import React from "react";
import axios from 'axios';

import {
    Typography,
    makeStyles,
    Card,
    CardContent,
    Divider,
    Grid,
    Button,
} from "@material-ui/core";

import {
    GetApp
} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    text: {
      textAlign: 'center',
      fontSize: '1.25em',
      color: '#1E58F9',
      fontWeight: 400,
    },
    labels: {
        fontSize: 14,
        color: '#9D9D9D',
        marginBottom: theme.spacing(4),
    },
}));

export default function OverviewCard(props){

    var classes = useStyles();

    return (
      <Card style={{height: '100%'}}>
        <CardContent>
          <Typography className={classes.labels}>
            {props.name}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            <img
              src={process.env.PUBLIC_URL + props.img}
              style={{width: 20, height: 20, color: '#1E58F9'}}
            />
            <span style={{marginLeft: 15, fontSize: 20, color: '#1B1928'}}>{props.val}</span>
          </div>
        </CardContent>
      </Card>
    );
}
