import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    withStyles,
    Grid,
    makeStyles,
    StepConnector,
    Card,
    CardContent,
    Typography,
} from "@material-ui/core";

import {
    LocalShipping,
    House,
    Assignment,
    Input,
    HourglassEmptyRounded,
    PriorityHigh
} from '@material-ui/icons';

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


// Styles for the TimeLine connector
const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
})(StepConnector);

// Styles for the TimeLine icons
const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(33,242,150) 0%, rgb(33,150,200) 50%, rgb(50,100,242) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
      'linear-gradient( 136deg, rgb(113,113,242) 0%, rgb(64,233,87) 50%, rgb(138,242,135) 100%)',
    },
    onHold: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    },
    cardShadow: {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);",
      margin: "25px 0px",
    },
    stepper: {
      backgroundColor: 'transparent'
    },
    status: {
      color: "#3B5B4B",
      fontWeight: "bold",
    },
    holdStatus: {
      color: "red",
      fontWeight: "bold"
    }
});

// Returns the appropriate icon for the step
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, error, icon, onHold } = props;

    const icons = {
        1: <HourglassEmptyRounded/>,
        2: <Assignment />,
        3: <Input />,
        4: <LocalShipping />,
        5: <House />
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active && !onHold,
          [classes.completed]: completed,
          [classes.onHold]: active && onHold
        })}
      >
        {onHold && active && <PriorityHigh/>}
        {onHold && !active && icons[String(icon)]}
        {!onHold && icons[String(icon)]}
      </div>
    );
}

const getStatusLabel = (orderStatus) => {
  var statusLabels = {
    'Engineering': 'DFM Check & Engineering',
    'Production': 'In Production',
    'Shipped': 'Order Shipped',
    'Completed': 'Order Arrived',
    'Hold': 'On Hold'
  };
  return statusLabels[orderStatus]
};

// Returns the TimeLine component
function MobileOrderTimeLine(props){
    const classes = useColorlibStepIconStyles();
    const label = getStatusLabel(props.orderStatus)

    return (
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.cardShadow}>
              <CardContent>
                <Typography
                  className={
                    props.holdStatus === "OnHold" ?
                    classes.holdStatus :
                    classes.status
                  }
                  align="center"
                  variant="h5"
                >
                  {label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
    );
}

export default MobileOrderTimeLine;
