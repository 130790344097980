import React from "react";
import {
  Grid,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    color: 'black',
  },
}));

function FooterBar() {
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>© 2023 Summit Interconnect, Inc.</Typography>
        </Grid>
        <Grid item>
          <Typography>
            <a href="tel:8772640343">
              (877)-264-0343
            </a>
          </Typography>
          <Divider orientation="vertical" />
          <Typography>
            <a href={`mailto:${process.env.REACT_SUPPORT_EMAIL}`}>
              {process.env.REACT_SUPPORT_EMAIL}
            </a>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <a href="https://www.summitinterconnect.com/terms-conditions/">
              Terms & Conditions
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default FooterBar;
