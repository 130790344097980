import React, { useEffect, useState } from "react";
import { useParams, withRouter, Redirect, useLocation } from 'react-router-dom';
import {
    makeStyles,
    useMediaQuery,
    Card,
    CardContent,
    Grid,
    Typography
} from "@material-ui/core";

import { useTheme } from '@material-ui/core/styles';
import CommonOrderOverview from '../../common/CommonOrderOverview';
import CombinedOrderTimeLine from './CombinedOrderTimeLine';
import MobileOrderTimeLine from './MobileOrderTimeLine';
import SalesRep from '../../common/SalesRep';
import BackGround from '../../static/BackGround';
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useRumAction } from '@datadog/rum-react-integration';
import { RumComponentContextProvider } from '@datadog/rum-react-integration';

const useStyles = makeStyles((theme) => ({
    root: {
      marginLeft: '10%',
      marginRight: '10%',
      marginBottom: '10%',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    refNum: {
      fontFamily: `'Gelion', sans-serif`,
      fontSize: 30,
      color: '#1B192B',
    },
    underline: {
      borderBottom: '2px dashed #500E58',
      color: theme.palette.primary.main,
    },
    labels: {
      fontSize: 14,
      color: '#9D9D9D',
      marginBottom: theme.spacing(2),
    },
    info: {
      fontSize: 22,
      color: 'black'
    }
}));


// Main OrderStatus Component that calls other related components like TimeLine
function OrderStatusChild(props){
    const isMobile = !useMediaQuery('(min-width:600px)');
    // state variables
    const [redirect, setRedirect] = useState(false);
    const [currUrl, setCurrUrl] = useState(useLocation().pathname);
    const [pageState, setPageState] = useState(1); // 0 - pulling data | 1 - received valid data | 2 - error
    const { order } = props;
    const addAction = useRumAction("OrderStatusChild");

    useEffect(() => {
      addAction('OrderViewed')
    }, []);

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const classes = useStyles();
 
    return (
        <BackGround height={-40}>
        { pageState === 1 && // data received
          <Grid className={classes.root}>
            <Grid container item className={classes.spacing} alignItems="baseline">
              <Grid item xs={4}>
                <span className={classes.refNum}>Order No. <span className={classes.underline}>
                    {order.ordernumber}
                  </span>
                </span>
              </Grid>
              {<Grid item xs={8}>
                { isMobile ?
                  <MobileOrderTimeLine orderStatus={order.orderstatus} holdStatus={order.holdstatus}/>
                : <CombinedOrderTimeLine orderDate={order.orderdate} orderStatus={order.orderstatus} statusSteps={order.status} holdStatus={order.holdstatus}/>
                }
              </Grid>}
              <Grid container justifyContent='space-between' alignItems='stretch' spacing={2}>
                <Grid item xs={6} md={3}>
                  <Card style={{height: '100%'}}>
                    <CardContent>
                      <Typography className={classes.labels}>
                        Part Number
                      </Typography>
                      <Typography className={classes.info}>
                        {order.partnumber}{order.partrevision == null ? "" : `-` + order.partrevision}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Card style={{height: '100%'}}>
                    <CardContent>
                      <Typography className={classes.labels}>
                        PO Number
                      </Typography>
                      <Typography className={classes.info}>
                        {order.ponumber}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card style={{height: '100%'}}>
                      <CardContent>
                        <Typography className={classes.labels}>
                          {order.delivery_title}
                        </Typography>
                        <Typography className={classes.info}>
                          {order.arrival_date}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                <Grid item xs={6} md={3}>
                  {order.salesrepname && order.salesrepname.length > 0 && (
                    <SalesRep
                      email={order.salesrepemail}
                      name={order.salesrepname}
                      phone={order.salesrepphone}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <RumComponentContextProvider 
                componentName="OrderStatusChild" 
              >
                <CommonOrderOverview orderData={order}/>
              </RumComponentContextProvider>
            </Grid>
          </Grid>
        }
        </BackGround>
    );
}

export default withRouter(OrderStatusChild);
