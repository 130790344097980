import React from "react";

import {
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Table,
    Typography,
    makeStyles,
    Card,
    CardContent,
    Grid,
    Paper
} from "@material-ui/core";
import { ClassNames } from "@emotion/react";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "20px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    TableLabel: {
        fontWeight: "bold"
    },
    cardTitle: {
        fontSize: 28,
        marginTop: "1%",
        fontWeight: "bold",
        marginBottom: "2%"
    },
    spec: {
      color: '#9D9D9D',
      fontSize: '0.9em'
    },
    val: {
      backgroundColor: 'rgba(249, 244, 249, 0.5)',
      fontSize: '0.9em',
      width: '10rem'
    },
}));

export default function SpecificationsTable(props){

    const classes = useStyles();

    var { data } = props;
    
    const thickness = parseFloat(data.thickness).toFixed(3) * 1000;
    const traceSpace = parseFloat(data.minspace).toFixed(3) * 1000;

    const specs1 = {
        'Layers': data.layercount,
        'Board Thickness': thickness > 0 ? `${thickness} mils` : '',
        'Dimensions': `${parseFloat(data.partwidth).toFixed(3)} x ${parseFloat(data.partlength).toFixed(3)} in`,
        'Min Trace/Space': traceSpace > 0 ? `${traceSpace} mils` : '',
    }
    const specs2 = {
        'Surface Finish': data.surfacefinish,
        'Material': (data.material ?? '') + (data.material2 ?? ''),
        'Silkscreen Color': data.silkcolor,
        'Soldermask Color': data.maskcolor,
    }

    return(
        <Grid container alignItems="center" justifyContent='center'>
          <Grid item xs={12} md={6}>
            <SpecTable specs={specs1} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SpecTable specs={specs2} />
          </Grid>
        </Grid>
    );
}

function SpecTable({specs}){
    const classes = useStyles();
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {
              Object.keys(specs).map((spec,index) => (
                <TableRow key={index}>
                  <TableCell className={classes.spec}>{spec}</TableCell>
                  <TableCell className={classes.val}>{specs[spec]}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
}
