import React from 'react';

import {
    AppBar,
    Box,
    Grid,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Card,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import moment from "moment";

function tabProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  deliveryTimeLine: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  deliveryDate: {
    fontSize: 18,
    margin: theme.spacing(1),
  },
  titles: {
    marginTop: "3%",
    fontSize: "2em",
    fontWeight: "bold",
  },
  labels: {
    fontSize: '1rem',
    fontWeight: "bold",
    padding: "6px",
  },
  timeline: {
    marginTop: "3%",
    maxHeight: 300,
    overflowY: "scroll",
  },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Grid container
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{p: 3}}>
            {children}
          </Box>
        )}
      </Grid>
    );
}

export default function DeliveryTimeline(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const formatTime = (timestamp) => {
      return moment(moment.utc(timestamp)).local().format("MMM D, YYYY");
    };

    const CheckStepIcon = React.useCallback(() => {
      return (<CheckCircleIcon style={{ fill: 'green' }} />);
    }, []);

    // Shipment steps
    const steps = React.useMemo(() => {
      return props.shipments[value]?.tracking_info?.tracking_details?.slice(0).reverse();
    }, [props.shipments, value]);

    const trackingInfo = React.useMemo(() => {
      return props.shipments[value] ? props.shipments[value].tracking_info : null;
    }, [props.shipments, value]);

    const quantity = React.useMemo(() => {
      return props.shipments[value]?.quantity;
    }, [props.shipments, value]);

    const arrivalDate = React.useMemo(() => {
      return trackingInfo?.status === "delivered"
        ? formatTime(steps[0].datetime.split("T")[0])
        : trackingInfo?.est_delivery_date?.length > 0
          ? formatTime(trackingInfo.est_delivery_date.split("T")[0])
          : "";
    }, [steps, trackingInfo]);

    return (
      <Card>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {props.shipments.map((_, index) => (
              <Tab key={index} label={"Shipment " + (index+1)} icon={props.shipments[index].tracking_info?.status=== "delivered" ? <CheckCircleOutlineIcon style={{ color: 'green' }}/> : null} {...tabProps(index)} />
            ))}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={value}>
          <Grid item xs={12}>
          {arrivalDate.length > 0
            ? (trackingInfo?.status) === "delivered" ? (
              <Typography className={classes.labels}>Delivered on: {arrivalDate}</Typography>
            ) : (
              <Typography className={classes.labels}>Estimated Arrival Date: {arrivalDate}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
                <Typography className={classes.labels}>Shipped On: {formatTime(props.shipments[value].ship_date)}</Typography>
                <Typography className={classes.labels}>Quantity Shipped: {quantity}</Typography>
                {trackingInfo && (
                  <>
                  <Typography className={classes.labels}>Shipped via {trackingInfo.carrier}</Typography>
                  <Typography className={classes.labels}>Tracking: {trackingInfo.tracking_code}</Typography>
                  </>
                )}
          </Grid>
          <Stepper className={classes.timeline} activeStep={-1} orientation="vertical">
            {steps && steps.map((step, index) => (
              <Step key={index} expanded={true}>
                <StepLabel StepIconComponent={CheckStepIcon}>
                  {step.datetime.split("T")[0]}
                </StepLabel>
                <StepContent>
                  <Typography>{step.message}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </TabPanel>
      </Card>
    );
  }
