import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Grid,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    makeStyles,
    Switch,
    withStyles,
    Typography,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import axios from 'axios';
import { useOktaAuth } from "@okta/okta-react";

const REGISTER_INITIAL_TEAM_PRIMARY_ENDPOINT = "/system/teams";
const GET_FULL_COMPANY_INFO_ENDPOINT = '/system/companies/byName';

const useStyles = makeStyles((theme) => ({
    root: {
      width: "80%",
      marginLeft: "10%",
      minHeight: "100vh",
      marginBottom: "5%"
    },
}));

export default function AddTeamAndPrimary(){

    const classes = useStyles();

    const [company, setCompany] = useState({});
    const [teamName, setTeamName] = useState("");
    const [primaryEmail, setPrimaryEmail] = useState("");
    const [primaryName, setPrimaryName] = useState("");
    const [primaryPass, setPrimaryPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [arkeoCompanyId, setArkeoCompanyId] = useState("");
    const [showFields, setShowFields] = useState();
    const [vendor, setVendor] = useState({
        code: 'rcs',
        name: 'Royal Circuits Solutions'
    });
    const [allCustomers, setAllCustomers] = useState([]);
    const [selectedCompanyInfo, setSelectedCompanyInfo] = useState({});
    const [pageState, setPageState] = useState(0);
    const [isTeamAdmin, setIsTeamAdmin] = useState(false);

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const handleSubmit = () => {
        if(primaryPass !== confirmPass){
            alert("passwords don't match!");
            return;
        }
        axios.post("/api" + REGISTER_INITIAL_TEAM_PRIMARY_ENDPOINT, {
            companyName: company.name,
            teamName: teamName,
            isTeamAdmin: isTeamAdmin,
            primaryEmail: primaryEmail,
            primaryName: primaryName,
            primaryPass: primaryPass,
            arkeoCompanyId: company.arkeoCompanyId,
            vendor: vendor.code,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            alert(res.data);
            setCompany({name: '', arkeoCompanyId: -1});
            setTeamName("");
            setPrimaryEmail("");
            setPrimaryName("");
            setPrimaryPass("");
            setConfirmPass("");
            handleCompanyDropdownChange(company);
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    const handleCompanyDropdownChange = (newCompany) => {
        if(newCompany !== null){
            setCompany(newCompany);
            setArkeoCompanyId(newCompany.arkeoCompanyId);
            axios.get('/api' + GET_FULL_COMPANY_INFO_ENDPOINT, {
                params: {
                    name: newCompany.name
                },
                headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
            })
            .then(res => {
                console.log('res', res);
                setSelectedCompanyInfo(res.data);
                setPageState(1);
            })
            .catch(err => {
                console.log('err', err)
                setPageState(1);
                setSelectedCompanyInfo({teams: []});
                alert("company info not found");
            })
        }
    }

    return(
        <Grid container direction='column' className={classes.root} spacing={3}>
            <Grid item xs={9}>
                <h2>Add new team and primary contact</h2>
            </Grid>
            <Grid container item xs={6} alignItems='center'>
                <FormControl style={{minWidth: '100%', minHeight: '100%'}}>
                    <InputLabel id="vendor-select-label">Select Vendor</InputLabel>
                    <Select
                        labelId="vendor-select-label"
                        id="vendor-select"
                        value={vendor.name}
                        renderValue={() => {return <Typography>{vendor.name}</Typography>}}
                        onChange={(e) => setVendor(e.target.value)}
                    >
                        <MenuItem value={{name: 'Royal Circuits Solutions', code: 'rcs'}}>Royal Circuits Solutions</MenuItem>
                        <MenuItem value={{name: 'Advanced Assembly', code: 'aa'}}>Advanced Assembly</MenuItem>
                        <MenuItem value={{name: 'South Coast Circuits', code: 'scc'}}>South Coast Circuits</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <Autocomplete
                    id="combo-box"
                    key={company}
                    options={allCustomers}
                    autoSelect={true}
                    getOptionLabel={option => option.name ? `${option.name}` : ''}
                    renderInput={(params) => <TextField {...params} label="Company Name" variant="outlined"/>}
                    value={company}
                    onChange={(event, newValue) => handleCompanyDropdownChange(newValue)}
                    getOptionSelected={(option, value) => {
                        return option.name === value.name
                    }}
                />
            </Grid>
            {pageState > 0 &&
                <>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>Company Teams:</Typography>
                    </Grid>
                {Object.keys(selectedCompanyInfo).length > 0 &&
                    Object.keys(selectedCompanyInfo.teams).map(name => {
                        return (
                            <Grid item xs={5} key={name}>
                                <TeamInfoCard companyName={selectedCompanyInfo.name} teamName={name} team={selectedCompanyInfo.teams[name]}/>
                            </Grid>
                        );
                    })
                }
                </Grid>
                {pageState === 1 &&
                <Grid item>
                    <Button variant='contained' color='primary' onClick={() => setPageState(2)}>Add new Team</Button>
                </Grid>
                }
                </>
            }
            {pageState > 1 &&
                <>
                <Grid item xs={9}>
                    <TextField fullWidth={true} disabled variant="outlined" id="arkeoCompanyId" label="Arkeo Company Id" onChange={(e) => setArkeoCompanyId(e.target.value)} value={arkeoCompanyId} InputLabelProps={{shrink: arkeoCompanyId > 0 ? true : false}}/>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                        <TextField fullWidth={true} variant="outlined" id="teamName" label="Team name" onChange={(e) => setTeamName(e.target.value)} value={teamName}/>
                        </Grid>
                        <Grid item>
                        <FormGroup row>
                            <FormControlLabel
                            control={
                                <Checkbox fullWidth={true} variant="outlined" id="teamAdmin" label="Team Admin" onChange={(e) => setIsTeamAdmin(e.target.checked)} value={isTeamAdmin}/>
                            }
                            label="Make Team Admin"
                            />
                        </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <TextField fullWidth={true} variant="outlined" id="primaryEmail" label="Primary Contact Email" onChange={(e) => setPrimaryEmail(e.target.value)} value={primaryEmail}/>
                </Grid>
                <Grid item xs={9}>
                    <TextField fullWidth={true} variant="outlined" id="primaryName" label="Primary Contact Name" onChange={(e) => setPrimaryName(e.target.value)} value={primaryName}/>
                </Grid>
                <Grid item xs={9}>
                    <TextField fullWidth={true} variant="outlined" id="primaryPass" label="Primary Contact Password" onChange={(e) => setPrimaryPass(e.target.value)} value={primaryPass}/>
                </Grid>
                <Grid item xs={9}>
                    <TextField fullWidth={true} variant="outlined" id="confirmPass" label="Confirm Contact Password" onChange={(e) => setConfirmPass(e.target.value)} value={confirmPass}/>
                </Grid>
                <Grid item xs={9}>
                    <Button variant="contained" color='primary' onClick={handleSubmit}>Submit</Button>
                </Grid>
                </>
            }
        </Grid>
    );

}

function TeamInfoCard(props) {

    const ADD_NEW_VENDOR_TO_TEAM_ENDPOINT = '/system/vendors'

    const team = props.team;

    const [newVendor, setNewVendor] = useState({
        name: '',
        code: ''
    });

    const [showAddNewVendor, setShowAddNewVendor] = useState(false);

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const handleAddNewVendorSubmit = () => {
        if(newVendor === ''){
            alert('Please make a vendor selection');
            return;
        }
        if(team.vendor.indexOf(newVendor) !== -1){
            alert('Vendor is already a part of the team');
            return;
        }
        axios.post('/api' + ADD_NEW_VENDOR_TO_TEAM_ENDPOINT, {
            companyName: props.companyName,
            teamName: props.teamName,
            newVendor: newVendor.code,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            alert("New vendor added");
        })
        .catch(err => {
            alert(err.response.data);
        })
    }

    return(
        <Card>
            <CardContent>
                <Typography color="textSecondary" variant='h5'>{props.teamName}</Typography>
                <br/>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Typography>
                            <span style={{fontWeight: 'bold'}}>Team Vendor:</span>  {(team.vendor.join(', ')).toUpperCase()}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontWeight: 'bold'}}>Member Emails:</Typography>
                        {
                            team.memberEmails.map(email => {
                                return(
                                    <Typography key={email}>{email} <span style={{color: 'red'}}>{(team.primaryContact === email) ? '(team primary)' : ''}</span></Typography>
                                );
                            })
                        }
                    </Grid>
                    {!showAddNewVendor &&
                    <Grid item xs={4}>
                        <Button variant='contained' color='primary' onClick={() => setShowAddNewVendor(true)}>Add New Vendor</Button>
                    </Grid>
                    }
                    {showAddNewVendor &&
                    <>
                    <Grid item xs={12}>
                        <FormControl style={{minWidth: '100%', minHeight: '100%'}}>
                            <InputLabel id="vendor-select-label">New Vendor</InputLabel>
                            <Select
                                labelId="vendor-select-label"
                                id="vendor-select"
                                value={newVendor.name}
                                renderValue={() => {return <Typography>{newVendor.name}</Typography>}}
                                onChange={(e) => setNewVendor(e.target.value)}
                            >
                                <MenuItem value={{name: 'Royal Circuits Solutions', code: 'rcs'}}>Royal Circuits Solutions</MenuItem>
                                <MenuItem value={{name: 'Advanced Assembly', code: 'aa'}}>Advanced Assembly</MenuItem>
                                <MenuItem value={{name: 'South Coast Circuits', code: 'scc'}}>South Coast Circuits</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={handleAddNewVendorSubmit}>Add Vendor</Button>
                    </Grid>
                    </>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}
