/* Loading.tsx
 * This component renders a spinning circle to indicate something is loading.
 */

import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//import Lottie from 'react-lottie';
//import animationData from "./lottie-circuit-light.json";
//import animationDataDark from "./lottie-circuit-dark.json";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles/";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.modal + 10,
  },
  /*searching: {
    width: "80%",
    marginLeft: "10%",
    height: "100vh",
  },*/
}));

function Loading(props) {
  const classes = useStyles();

  /*const theme = useTheme();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: theme.palette.type === 'dark' ? animationDataDark : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  {pageState === 0 && // loading state
    <Grid container justifyContent="center" className={classes.searching}>
      <Grid item>
        <Lottie options={defaultOptions}
          height={400}
          width={400}
        />
        <h2 style={{textAlign: "center"}}>Loading...</h2>
      </Grid>
    </Grid>
  }*/

  return (
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={6}></Grid>
        <Grid item>
          <CircularProgress variant="indeterminate" disableShrink={true} />
        </Grid>
        <Grid item>
          <Typography variant="h6">{props.text ? props.text : "Loading..."}</Typography>
        </Grid>
      </Grid>
  );
}


export default Loading;
