import React from 'react';

import Rectangle from './Rectangle 2595.png';
import EllipseLeft from './EllipseLeft.png';
import EllipseRight from './EllipseRight.png';
import Vector from './Vector 48.png';
import VectorCircle from './Ellipse 169.png';

import {makeStyles} from "@material-ui/core";

export default function BackGround(props){
    const h = props.height ?? 0;
    const showVector = props.showVector ?? true;
    const vectors = `url(${Vector}), url(${VectorCircle}), `;
    const vectorPositions = `top ${150+h}px left 0px, top ${115+h}px left 115px, `
    const useStyles = makeStyles((theme) => ({
        pageRoot: {
          backgroundImage: `${showVector ? vectors : ''}url(${EllipseLeft}), url(${EllipseRight}), url(${Rectangle}) `,
          backgroundPosition: `${showVector ? vectorPositions : ''}top -200px left -100px, top -170px right -200px, center top`,
          backgroundRepeat: `${showVector ? 'no-repeat, no-repeat, ' : ''}no-repeat, no-repeat, no-repeat`,
          backgroundSize: `${showVector ? '140px 100px, 40px 40px, ' : ''}auto, auto, 100% ${250+h}px`,
          width: "100%",
          padding: "0%",
        }
    }));
    const classes = useStyles();

    return(
        <div className={classes.pageRoot}>
            {props.children}
        </div>
    );
}
