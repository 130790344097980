import React from "react";
import {withRouter} from 'react-router-dom';
import {
  Button,
  Grid,
  makeStyles,
  Toolbar,
  Box
} from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { useOktaAuth } from "@okta/okta-react";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  signInButton: {
    marginLeft: "auto",
    border: 'white'
  },
}));

function HeaderBar(props) {
  const classes = useStyles();

  const { authState, oktaAuth } = useOktaAuth();

  const loginWithRedirect = () => oktaAuth.signInWithRedirect( {originalUri: '/'} );
  const logOut = () => oktaAuth.signOut();

  let buttonText = authState ? "logout" : "login";
  let btnLogic = authState ? logOut : loginWithRedirect;

  return (
    <Toolbar className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={props.onClick}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuRoundedIcon />
      </IconButton>
      <Box
        sx={{
          display: {
            xxs: 'block',
            xs: 'block',
            sm: 'none',
            md: 'none',
          }
        }}
      >
        <a href="/">
          <img
            src={process.env.PUBLIC_URL + '/logo.png'}
            height={50}
            alt='summit logo'
          />
        </a>
      </Box>
      <Grid container justifyContent="flex-end" spacing={3} alignItems="center">
          <Grid item>
            <Button
              className={classes.signInButton}
              variant="contained"
              onClick={btnLogic}
            >
              {buttonText}
            </Button>
          </Grid>
      </Grid>
    </Toolbar>
  );
}

export default withRouter(HeaderBar);
