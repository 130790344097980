import React, { useEffect, useState } from 'react';
import {Switch, Route, BrowserRouter, Redirect} from 'react-router-dom';

import AddAdminFromEmail from './AddAdminFromEmail';
import AddTeamAndPrimary from './AddTeamAndPrimary';
import EditContact from './EditContact';
import AdminHome from './AdminHome';
import ListNewFeaturesForPopup from './ListNewFeaturesForPopup';
import FileUpload from './FileUpload';
import Loading from '../../static/Loading';
import { useOktaAuth } from "@okta/okta-react";

export default function Admin(){

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    const isAdmin = ['SystemAdmin'].some(g => currUser.groups.includes(g));

    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (currUser) {
            if(isAdmin !== null && !isAdmin){
                setRedirect(true);
            }
        }
    }, [currUser, isAdmin]);

    return(
        <>
        {redirect && <Redirect to="/"/>}
        {isAdmin &&
            <BrowserRouter>
                <Switch>
                    <Route exact path="/admin">
                        <AdminHome/>
                    </Route>
                    <Route path="/admin/addAdminFromEmail">
                        <AddAdminFromEmail/>
                    </Route>
                    <Route path='/admin/addTeamAndPrimaryContact'>
                        <AddTeamAndPrimary />
                    </Route>
                    <Route path="/admin/editContact">
                        <EditContact/>
                    </Route>
                    <Route path="/admin/newFeatures">
                        <ListNewFeaturesForPopup/>
                    </Route>
                    <Route path="/admin/fileUpload">
                        <FileUpload/>
                    </Route>
                </Switch>
            </BrowserRouter>
        }
        {isAdmin === null &&
            <Loading/>
        }
        </>
    );
}
