import React, {useState} from 'react';
import { useSnackbar } from 'notistack';

import{
    TextField,
    Button,
    Grid,
    makeStyles,
    Typography,
    Switch,
    Radio,
    withStyles
} from '@material-ui/core';
import axios from 'axios';
import { useOktaAuth } from "@okta/okta-react";

const ADD_ADMIN_FROM_EMAIL_ENDPOINT = '/system/admins';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "3%",
        marginLeft: "5%",
        minHeight: "80vh"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
    sectionHeader: {
        fontWeight: "bold",
        fontSize: "1.5em"
    }
}));

const CustomSwitch = withStyles({
    switchBase: {
      color: 'red',
      '&$checked': {
        color: 'green',
      },
      '&$checked + $track': {
        backgroundColor: 'green',
      },
    },
    checked: {},
    track: {
      backgroundColor: 'red',
    },
  })(Switch);

export default function AddAdminFromEmail(){

    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [email, setEmail] = useState('');
    const [addAdmin, setAddAdmin] = useState(true);
    const [company, setCompany] = useState('rcs');
    const [role, setRole] = useState('sales');
    
    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const handleEmailSubmit = () => {
        if(email === ""){
            enqueueSnackbar('Email cannot be empty', {variant: 'error'});
            return;
        }
        axios.put("/api" + ADD_ADMIN_FROM_EMAIL_ENDPOINT, {
            email: email,
            addAdmin: addAdmin,
            role: role,
            company: company,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            enqueueSnackbar(res.data, {variant: 'success'});
            setEmail("");
        })
        .catch(e => {
            console.log(e);
            enqueueSnackbar(e.response.data, {variant: 'error'});
        })
    }

    const handleCompanyChange = (e) => {

    }

    return(
        <div className={classes.root}>
        <Grid container spacing={7} direction='column'>
            <Grid container item>
                <Typography className={classes.pageHeader}>Add Admin by Email</Typography>
            </Grid>
            <Grid container item xs={6} alignItems='center'>
                <Grid item><Typography>Delete Admin</Typography></Grid>
                <Grid item>
                    <CustomSwitch
                        checked={addAdmin}
                        onChange={(e) => setAddAdmin(e.target.checked)}
                        name="addAdmin"
                    />
                </Grid>
                <Grid item><Typography>Add Admin</Typography></Grid>
            </Grid>
            <Grid container item>
                <Grid container item xs={12} spacing={3} direction='column'>
                    <Grid item><Typography className={classes.sectionHeader}>Add / Remove Admin</Typography></Grid>
                    <Grid item>
                        <TextField 
                            fullWidth 
                            variant="outlined" 
                            onChange={(e) => setEmail(e.target.value)} 
                            value={email} 
                            label={`Enter email to ${addAdmin ? 'add' : 'remove'} admin`}
                        />
                    </Grid>
                    {addAdmin && 
                    <>
                        <Grid item container>
                            <Grid item>
                                <Typography variant='h6'>Company:</Typography>
                            </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <Radio
                                        checked={company === 'rcs'}
                                        onChange={(e) => setCompany(e.target.value)}
                                        value="rcs"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Royal Circuits Solutions</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <Radio
                                        checked={company === 'aa'}
                                        onChange={(e) => setCompany(e.target.value)}
                                        value="aa"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Advanced Assembly</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item>
                                <Typography variant='h6'>Role:</Typography>
                            </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <Radio
                                        checked={role === 'sales'}
                                        onChange={(e) => setRole(e.target.value)}
                                        value="sales"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Sales</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems='center'>
                                <Grid item>
                                    <Radio
                                        checked={role === 'support'}
                                        onChange={(e) => setRole(e.target.value)}
                                        value="support"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Support</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    }
                    <Grid item>
                        <Button 
                            variant='contained' 
                            color='primary' 
                            onClick={handleEmailSubmit}
                        >
                            {addAdmin ? 'Add' : 'Remove'} Admin
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </div>
    );
}