import React from "react";
import axios from 'axios';

import {
    Typography,
    makeStyles,
    Card,
    CardContent,
    Divider,
    Grid,
    Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: 14,
      color: '#9D9D9D',
    },
    img: {
      width: 35,
      height: 35,
    },
    text: {
      color: '#9D9D9D',
      fontSize: 13,
    },
    name: {
      color: '#1B1928',
      fontSize: 16
    }
}));

export default function SalesRep(props){

    var classes = useStyles();

    return (
      <Card style={{height: '100%'}}>
        <CardContent>
          <Typography className={classes.header}>
            Sales Representative
          </Typography>
          <Typography className={classes.name}>{props.name}</Typography>
          <Typography className={classes.text}>
            {props.phone}
          </Typography>
          <Typography className={classes.text}>
            {props.email}
          </Typography>
        </CardContent>
      </Card>
    );
}
