import React, {useRef, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from "notistack";
import moment from 'moment';
import { useOktaAuth } from "@okta/okta-react";
import { useRumAction, useRumError } from '@datadog/rum-react-integration';
import { decryptQueryParams } from "../hooks/cipher";
import {
    Typography,
    makeStyles,
    Grid,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    TableFooter,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton,
    Collapse,
    Box,
    CircularProgress
} from "@material-ui/core";

import{
    Cancel,
    KeyboardArrowDown,
    KeyboardArrowUp,
    CheckBox,
    CheckBoxOutlineBlank
} from '@material-ui/icons'
import DownloadIcon from '@mui/icons-material/Download';


const useStyles = makeStyles((theme) => ({
    root: {
      margin: theme.spacing(2),
    },
    dialog: {
      margin: theme.spacing(1),
    },
    dialogBox: {
      minWidth: 800,
      margin: theme.spacing(1),
    },
}));

const DOWNLOAD_FILE_ENDPOINT = '/user/files/dl';
const DELETE_FILE_ENDPOINT = '/user/files';

export default function CustomerFiles(props){

    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const { ordernumber, iv, encryptedData } = useParams();
    const { releaseid, facility, systemname, isactive } = decryptQueryParams(iv, encryptedData);

    const { allItar, orderData } = props;

    const addAction = useRumAction("CustomerFiles");
    const addError = useRumError("CustomerFiles");

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    const isSystemAdmin = currUser.groups.includes('SystemAdmin');

    const FILE_UPLOAD_ENDPOINT = (isSystemAdmin) ? '/system/files' : '/user/files';
    const GET_FILES_FOR_REFERENCE_NUMBER_ENDPOINT = (isSystemAdmin) ? '/system/files' : '/user/files';

    const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [isItar, setIsItar] = useState(props.ITAR);
    const [fileType, setFileType] = useState(-1);
    const [uploading, setUploading] = useState(false);
    const [azureFiles, setAzureFiles] = useState([]);
    const [isInternalFile, setIsInternalFile] = useState(false);

    const fileInputRef = React.useRef(null);

    const fileTypes = {
        0: 'Compliance',
        1: 'Documentation',
        2: 'Customer Data',
        3: 'Misc.'
    }

    useEffect(() => {
        getAzureFiles();
    }, []);

    const handleFileInputChange = (e) =>{
        const selectedFile = e.target.files[0];
        setUploadFile(selectedFile);
    }

    const getAzureFiles = (callbackFunc=()=>{}) => {
        axios.get('/api' + GET_FILES_FOR_REFERENCE_NUMBER_ENDPOINT, {
          params: {
            releaseid: releaseid,
            systemname: systemname,
            facility: facility,
            isactive: isactive
          },
          headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
        })
        .then(res => {
            setAzureFiles(res.data);
        })
        .catch((err) => {
          addError('LoadFiles');
          enqueueSnackbar('Files failed to load.', {variant: 'warning'});
        })
        .finally(callbackFunc);
    }

    const handleFileUpload = () => {
        if(orderData.releaseid == null){
          enqueueSnackbar('Please try again', {variant: 'warning'});
          return;
        }
        if(!uploadFile){
            enqueueSnackbar('Please choose a file', {variant: 'warning'});
            return;
        }
        if(fileType === -1){
            enqueueSnackbar('Please choose a file type', {variant: 'warning'});
            return;
        }
        setUploading(true);
        const data = new FormData();
        data.append('file', uploadFile);
        data.append('fileType', fileTypes[fileType]);
        data.append('isITAR', isItar);
        data.append('isInternalFile', isInternalFile);
        axios.post('/api' + FILE_UPLOAD_ENDPOINT, data, {
          params: {
            releaseid: releaseid,
            systemname: systemname,
            facility: facility,
            isactive: isactive
          },
          headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + authState.accessToken.accessToken}
        })
        .then(res => {
            addAction('FileUpload')
            getAzureFiles(() => {
              setUploadFile(null);
              setUploading(false);
              setShowFileUploadDialog(false);
              enqueueSnackbar('Customer file uploaded', {variant: 'success'});
            });
        })
        .catch(err => {
            addError('FileUpload')
            setUploading(false);
            setUploadFile(null);
            setShowFileUploadDialog(false);
            enqueueSnackbar('File upload error. Please try again', {variant: 'error'});
        })
    }

    return(
        <>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Filename</TableCell>
                  <TableCell>Uploaded</TableCell>
                  <TableCell>ITAR</TableCell>
                  <TableCell></TableCell>
                  {isSystemAdmin && <TableCell>Category</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
              {(!azureFiles || azureFiles.length === 0) &&
                <>
                <TableRow>
                  <TableCell><Typography>No files found for {orderData.ordernumber}</Typography></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                </>
              }
              {
              azureFiles.map((file, index) => {
                return(
                  <FileTableRow key={index} allItar={props.allItar} file={file} orderData={orderData} getAzureFiles={getAzureFiles} />
                )
              })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <Button
                    variant='contained'
                    onClick={() => setShowFileUploadDialog(true)}
                    color='primary'
                  >
                    Upload File
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
            </Table>
          </TableContainer>
          <Dialog
            open={showFileUploadDialog}
            maxWidth="md"
            className={classes.dialogBox}
          >
            <DialogTitle id="add-file-dialog-title">Upload File</DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Please fill out the form below. Note that uploading files
                will automatically notify your sales rep.
              </DialogContentText>
              <Grid container alignItems='center' spacing={2}>
                <input
                    style={{ display: 'none' }}
                    id="upload-file"
                    name="upload-file"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                />
                <Grid item xs={12}>
                  <FormControl style={{width: "75%"}}>
                    <InputLabel id="file-type-select-label">File Type</InputLabel>
                    <Select
                        labelId="file-type-select-label"
                        id="file-type-select"
                        value={fileTypes[fileType]}
                        label="File type*"
                        onChange={(e) => setFileType(e.target.value)}
                        renderValue={() => {return <Typography>{fileTypes[fileType]}</Typography>}}
                    >
                      {
                        Object.entries(fileTypes).map(([key, val]) =>
                          <MenuItem key={key} value={key}>{val}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                {isSystemAdmin &&
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={isInternalFile} onChange={(e) => setIsInternalFile(e.target.checked)}/>} label="Internal" />
                    </FormGroup>
                  </Grid>
                }
                <Grid item xs={3}>
                  <Button
                    onClick={(e) => fileInputRef.current.click()}
                    color='primary'
                    variant='contained'
                  >
                    Browse
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    value={(uploadFile) ? uploadFile.name : "No file chosen"}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialog}>
              {uploading &&
              <CircularProgress variant="indeterminate" disableShrink={true} />
              }
              <Button
                  onClick={handleFileUpload}
                  variant='contained'
                  color='primary'
              >
                Upload
              </Button>
              <Button
                  onClick={() => setShowFileUploadDialog(false)}
                  variant='outlined'
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
    );
}

export const useDownloadFile = ({
  downloadFile,
  preDownloading,
  postDownloading,
  onError,
}) => {
  const fileRef = useRef(null);
  const [fileUrl, setFileUrl] = useState("");

  const download = async (filename) => {
    try {
      preDownloading();
      const { data } = await downloadFile(filename);
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      fileRef.current?.click();
      postDownloading();
      URL.revokeObjectURL(url);
    } catch (error) {
      onError();
    }
  };
  
  return { fileRef, fileUrl, download };
};

function FileTableRow(props){

    let { ordernumber, iv, encryptedData } = useParams();
    let { releaseid, facility, systemname, isactive } = decryptQueryParams(iv, encryptedData);

    const {enqueueSnackbar} = useSnackbar();
    const {file, orderData, getAzureFiles } = props;

    const [open, setOpen] = useState(false);
    const [downloading, setDownloading] = useState();
    const [deleting, setDeleting] = useState(false);

    const addAction = useRumAction("CustomerFiles");
    const addError = useRumError("CustomerFiles");

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    const isSystemAdmin = currUser.groups.includes('SystemAdmin');

    const preDownloading = () => {
      setDownloading(true);
    };

    const postDownloading = () => {
      addAction('FileDownload');
      setDownloading(false);
      enqueueSnackbar('File downloaded successfully', {variant: 'success'});
    };

    const onErrorDownloadFile = () => {
      addError('FileDownload');
      setDownloading(false);
      enqueueSnackbar('There was an error downloading.', {variant: 'error'});
    }

    const handleDownloadAzureFile = async (filename) => {
      return axios.get('/api' + DOWNLOAD_FILE_ENDPOINT, {
        params: {
          filename: filename,
          releaseid: releaseid,
          systemname: systemname,
          facility: facility,
          isactive: isactive
        },
        responseType:'arraybuffer',
        headers: { 
          'Authorization': 'Bearer ' + authState.accessToken.accessToken,
        },
      });
    }

    const handleDeleteFile = (filename) => {
        if(window.confirm('Are you sure you want to delete the file ' + filename)){
            setDeleting(true);
            axios.delete('/api' + DELETE_FILE_ENDPOINT, {
              params: {
                filename: filename,
                releaseid: releaseid,
                systemname: systemname,
                facility: facility,
                isactive: isactive
              },
              headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
            })
            .then(res => {
                getAzureFiles(() => {
                  setDeleting(false);
                  enqueueSnackbar('File deleted successfully', {variant: 'success'});
                });
            })
            .catch(err => {
                addError('FileDelete');
                setDeleting(false);
                enqueueSnackbar('File delete error. Please try again', {variant: 'error'});
            })
        }
    }

    const { fileRef, fileUrl, download } = useDownloadFile({
      downloadFile: handleDownloadAzureFile,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile
    });

    return(
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
            <TableCell>{file.filetype}</TableCell>
            <TableCell>{file.filename}</TableCell>
            <TableCell>{moment(parseInt(file.dateuploaded)).format('ll')}</TableCell>
            <TableCell>{(props.allItar || file.isITAR) ? <CheckBox/> : <CheckBoxOutlineBlank/>}</TableCell>
            <TableCell>
              <a ref={fileRef} href={fileUrl} download={file.filename} className="hidden"  />
              <Button
                  onClick={() => download(file.filename)}
              >
                  {(downloading) ? <CircularProgress variant="indeterminate" disableShrink={true} /> : <DownloadIcon />}
              </Button>
              <Button
                onClick={() => handleDeleteFile(file.filename)}
              >
                {(deleting) ? <CircularProgress variant="indeterminate" color='secondary' disableShrink={true} /> : <Cancel style={{color: 'red'}}/>}
              </Button>
            </TableCell>
            {isSystemAdmin &&
                <TableCell>
                    {file.isInternal ? 'Internal' : 'Customer'}
                </TableCell>
            }
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {file.history &&
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>User</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(file.history).reverse().map(historyItem=> (
                      <TableRow key={historyItem.time}>
                        <TableCell component="th" scope="row">
                          {moment(historyItem.time).format('ll')}
                        </TableCell>
                        <TableCell>{historyItem.actionType}</TableCell>
                        <TableCell>{historyItem.userEmail}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </Box>
          </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
