/* App.tsx
 * This file renders the BomBuilder application. Some overall components are
 * initialized here, like the SnackbarProvider, BackendHealth, and others.
 * There are also hotkeys to upload a file of packages / druid locks.
 * We can see the endpoints are routed into components here, such that the
 * index endpoint (/) renders <CreateProject />, and a specific job endpoint
 * (/job/<job_id>) renders <JobView />
 */

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Route, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { datadogRum } from '@datadog/browser-rum';

//components
import DashboardView from "./DashboardView";
import HeaderBar from "./static/HeaderBar";

require('dotenv').config()

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const ISSUER = process.env.REACT_APP_ISSUER;
const HOST = process.env.REACT_APP_HOST;
const REDIRECT_URI = `${HOST}${CALLBACK_PATH}`;
const SCOPES = process.env.REACT_APP_SCOPES;
const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const DATADOG_SERVICE = process.env.REACT_APP_DATADOG_SERVICE;
const DATADOG_VERSION = process.env.REACT_APP_DATADOG_VERSION;
const APPLICATION_ENV = process.env.REACT_APP_ENV;

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
};

const oktaAuth = new OktaAuth(config);

// Initialize Datadog monitoring
datadogRum.init({
  applicationId: DATADOG_APPLICATION_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'us3.datadoghq.com',
  service:DATADOG_SERVICE,
  env:APPLICATION_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog 
  version: DATADOG_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 50,
  trackUserInteractions: true,
  trackFrustrations: true,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: true,
  defaultPrivacyLevel:'allow',
  beforeSend: (event, context) => {
    if (event.type === 'error') {
      // Discard error
      if (event.error.message.includes('deprecated in StrictMode')) {
        return false;
      }
    }
  }
});

datadogRum.startSessionReplayRecording();

function App() {

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: `'Gelion', sans-serif`,
        },
        palette: {
          type: "light",
          primary: {
            light: "#EEE8EF",
            main: "#1E58F9",
            dark: "#002CBF",
          },
          secondary: {
            light: "#F0F0F0",
            main: "#000000",
            dark: "#9B9B9B",
          },
        },
      }),
    [prefersDarkMode]
  );

  const history = useHistory();
  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        maxSnack={2}
        autoHideDuration={3000}
      >
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
          <HeaderBar />
          <Route path="/login/callback">
            <LoginCallback/>
          </Route>
          <SecureRoute exact path={["/","/orderStatus/", "/customer", "/settings", "/account", "/orderStatus/:order_number/:iv/:encryptedData", "/admin"]}>
            <DashboardView/>
          </SecureRoute>
        </Security>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
