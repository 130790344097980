import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

import {
    withStyles,
    makeStyles,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Typography
} from "@material-ui/core";

import {
    LocalShipping,
    House,
    Assignment,
    Input,
    HourglassEmptyRounded,
    PriorityHigh,
    Settings
} from '@material-ui/icons';

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

// Styles for the TimeLine connector
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      border: '0.5px dashed #1E58F9'
    }
  },
  completed: {
    '& $line': {
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: 'rgba(30, 76, 249, 0.2)',//'#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

// Styles for the TimeLine icons
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    zIndex: 1,
    color: 'rgba(30, 76, 249, 0.7)',
    width: 50,
    height: 50,
    display: 'flex',
    boxShadow: '0px 7px 14px rgba(0, 0, 0, 0.05)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#1E58F9',
    color: 'white'
  },
  completed: {
    backgroundColor: 'lightgrey',
  },
  onHold: {
    backgroundColor: '#ff0f0f'
  },
  cardShadow: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);",
    margin: "25px 0px",
  },
  stepper: {
    backgroundColor: 'transparent'
  }
});

// Returns the appropriate icon for the step
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, icon } = props;
    const onHold = icon === 6;

    const icons = {
        1: <HourglassEmptyRounded/>,
        2: <Assignment />,
        3: <Input />,
        //4: <Settings />,
        4: <LocalShipping />,
        5: <House />,
        6: <PriorityHigh />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active && !onHold,
          [classes.completed]: completed,
          [classes.onHold]: active && onHold
        })}
      >
        {onHold && active && <PriorityHigh/>}
        {onHold && !active && icons[String(icon)]}
        {!onHold && icons[String(icon)]}
      </div>
    );
}

const defaultSteps = {
  'Placed': {
    icon: 1,
    label: 'Order Placed',
    orderrank: 1,
    statusdesc: 'Placed'
  },
  'Engineering': {
    icon: 2,
    label: 'Engineering',
    orderrank: 2,
    statusdesc: 'Engineering'
  },
  'Production': {
    icon: 3,
    label: 'In Production',
    orderrank: 4,
    statusdesc: 'Production'
  },
  'Shipped': {
    icon: 4,
    label: 'Order Shipped',
    orderrank: 5,
    statusdesc: 'Shipped'
  },
  'Completed': {
    icon: 5,
    label: 'Order Arrived',
    orderrank: 7,
    statusdesc: 'Completed'
  }
};

// Gets all Step names. Seperated for easy edits (if needed later)
function getStepNames(order_status, status_steps, order_date) {
  defaultSteps['Placed'].statusdate = order_date;

  // Order rank for hold status is intialised as after production stage
  let prevRank = 4;
  status_steps.forEach((step) => {
    if (step.statusdesc in defaultSteps) {
      defaultSteps[step.statusdesc] = {
        ...defaultSteps[step.statusdesc],
        ...step
      };
    } else if (step.statusdesc == "Hold") {
      defaultSteps[step.statusdesc] = {
        ...step,
        orderrank: prevRank + 1,
        icon: 6,
        label: 'On Hold'
      };
    }
    prevRank = defaultSteps[step.statusdesc].orderrank;
  });

  let result = Object.values(defaultSteps).sort((a,b) => a.orderrank - b.orderrank);
  let index = result.findIndex((step) => step.statusdesc == order_status);
  return [result, index];
};

// Returns the TimeLine component
function CombinedOrderTimeLine(props) {
    const classes = useColorlibStepIconStyles();
    const [steps, activeStep] = getStepNames(props.orderStatus, props.statusSteps, props.orderDate);

    const formatTime = (timestamp) => {
      return moment(moment.utc(timestamp)).local().calendar();
    }

    return (
      <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((status, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={() => ColorlibStepIcon({
                active: (index == activeStep),
                completed: (index < activeStep),
                error: false,
                icon: status.icon
              })}
            >
              {status.label}<br/>
              {status.statusdate && (
                <Typography variant="caption">
                  {formatTime(status.statusdate)}
                </Typography>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
}

export default CombinedOrderTimeLine;
