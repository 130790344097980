import React from "react";
import axios from 'axios';

import {
    Typography,
    makeStyles,
    Card,
    CardContent,
    Divider,
    Grid,
    Button,
} from "@material-ui/core";

import {
    GetApp
} from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import SpecificationsTable from './SpecificationsTable';

const useStyles = makeStyles((theme) => ({
    active: {
      margin: theme.spacing(1),
      backgroundColor: '#FFFFFF',
      border: '5px solid rgba(30, 88, 250, 0.2)',
      boxSizing: 'border-box',
      boxShadow: '0px 7px 14px rgba(0, 0, 0, 0.5)',
      borderRadius: 10,
    },
    inactive: {
      margin: theme.spacing(1),
      backgroundColor: '#EBEBEB',
    },
    img: {
      align: 'center',
      margin: theme.spacing(2),
      width: 250,
      height: 100
    },
    text: {
      textAlign: 'center',
      fontSize: '1.25em',
      color: '#000',
      fontWeight: 400,
    }
}));

export default function JobType(props){

    var classes = useStyles();

    return (
      <Card className={props.active ? classes.active : classes.inactive}>
        <CardContent>
          <Grid container justifyContent="center">
            <img
              src={process.env.PUBLIC_URL + '/' + props.img}
              alt={props.name}
              className={classes.img}
            />
          </Grid>
          {props.active ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>
              <CheckCircleIcon style={{fontSize: 20, color: '#1E58F9'}} />
              <span style={{marginLeft: 5, fontSize: "1.25em", color: '#000'}}>{props.name}</span>
            </div>
            :
            <Typography className={classes.text}>
              {props.name}
            </Typography>
          }
        </CardContent>
      </Card>
    );
}
