import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import { useSnackbar } from 'notistack';
import { encryptQueryParams } from "../hooks/cipher";

import Loading from '../static/Loading';
import BackGround from '../static/BackGround';
import ErrorPage from '../static/ErrorPage';
import MobileOrderTimeLine from '../components/OrderStatus/MobileOrderTimeLine';
import CombinedOrderTimeLine from './OrderStatus/CombinedOrderTimeLine';
import OrderPreview from '../common/OrderPreview';
import { useOktaAuth } from "@okta/okta-react";

import {
    makeStyles,
    useMediaQuery,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      marginLeft: '10%',
      marginRight: '10%',
      marginBottom: '10%',
      minHeight: '100vh'
    },
    container: {
        maxHeight: 350,
        overflow: "auto"
    },
    refNum: {
      fontSize: 30,
    },
    underline: {
      borderBottom: '2px dashed #500E58',
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
}));

const GET_ORDERS_BY_CUSTOMER_TEAM_ENDPOINT = "/user/orders";
const GET_ORDER_STATUS_BY_UNIQUE_ID = "/user/orders/status/byOrderId";
const GET_ORDERS_BY_SALES_REP_ENDPOINT = "/system/orders/bySalesRep";
const GET_USER_INFO_ENDPOINT = '/user/profile'

export default function Home(){

    const {enqueueSnackbar} = useSnackbar();

    const LIMIT = 5;
    const classes = useStyles();
    const isMobile = !useMediaQuery('(min-width:600px)');

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    const isSystemAdmin = currUser.groups.includes('SystemAdmin');

    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/");
    const [companyId, setCompanyId] = useState(-1);
    const [pageState, setPageState] = useState(0);
    const [mostRecentOrderStatus, setMostRecentOrderStatus] = useState("");
    const [mostRecentOrderStatusSteps, setMostRecentOrderStatusSteps] = useState([]);
    const [ordersData, setOrdersData] = useState([]);

    const getUserInfo = async () => {
      axios.get('/api' + GET_USER_INFO_ENDPOINT, {
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then(res => {
        if (res.data.firstName == null || res.data.lastName == null) {
          enqueueSnackbar('Please update your name in the Account page', {variant: 'warning'})
        }
      })
      .catch(err => {
        console.log("get user info error", err.message);
        setPageState(2);
        enqueueSnackbar('There was an error while loading your orders', {variant: 'error'});
      });
    };

    // Fetch order status details for most recent order
    const getOrderStatus = (order) => {
      axios.get("/api" + GET_ORDER_STATUS_BY_UNIQUE_ID, {
        params: {
          releaseid: order.releaseid,
          systemname: order.systemname,
          facility: order.facility,
          isactive: order.isactive,
        },
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then((res) => {
        setMostRecentOrderStatusSteps(prevState => [...prevState,...res.data]);
      })
      .catch((err) => {
        setPageState(2);
        enqueueSnackbar('There was a problem loading order status details', {variant: 'error'});
        console.error(err);
      })
    }

    // Fetch recent orders where user is the sales rep
    const getOrdersBySalesRep = () => {
      axios.get("/api" + GET_ORDERS_BY_SALES_REP_ENDPOINT, {
        params: {
          batch_num: 0,
          batch_size: LIMIT,
          search_string: '',
        },
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then((res) => {
        setOrdersData(res.data.orders);
        
        if (res.data.orders.length > 0) getOrderStatus(res.data.orders[0]);
        setPageState(1);
        enqueueSnackbar('Sales Rep orders loaded successfully', {variant: 'success'});
      })
      .catch((err) => {
        setPageState(2);
        console.error(err);
        enqueueSnackbar('There was an error while loading your orders', {variant: 'error'});
      })
    }

    // Fetch recent orders placed by user's team
    const getOrdersByCustomerTeam = () => {
      axios.get("/api" + GET_ORDERS_BY_CUSTOMER_TEAM_ENDPOINT, {
        params: {
          batch_num: 0,
          batch_size: LIMIT,
          search_string: '',
        },
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then((res) => {
        let orders = res.data.orders;
        let sortedOrders = orders.sort((a, b) => {
          return moment(b.duedate).diff(moment(a.duedate));
        });
        setOrdersData(sortedOrders);
        if (res.data.orders.length > 0) {
          getOrderStatus(res.data.orders[0]);
        }
        setPageState(1);
        enqueueSnackbar('Customer orders loaded successfully', {variant: 'success'});
      })
      .catch((err) => {
        enqueueSnackbar('There was a problem loading customer orders', {variant: 'error'});
        setPageState(2);
        console.error(err);
      });
    }

    useEffect(() => {
      if (currUser) {
        getUserInfo();
      }
    }, []);

    useEffect(() => {
      if (isSystemAdmin) {
        getOrdersBySalesRep();
      } else {
        getOrdersByCustomerTeam();
      }
    }, [isSystemAdmin]);

    useEffect(() => {
        if (ordersData === undefined || ordersData === null || ordersData.length === 0){
          return;
        }
        var data = ordersData[0];
        setMostRecentOrderStatus(data.orderstatus);
    }, [ordersData]);

    const handleSeeAllOrders = () => {
        setRedirectUrl("/customer");
        setRedirect(true);
    }

    const handleOrderDetails = (orderData) => (e) => {
      let cipher = encryptQueryParams({
        releaseid: orderData.releaseid,
        facility: orderData.facility,
        systemname: orderData.systemname,
        isactive: orderData.isactive
      });
      let path = `orderStatus/${orderData.ordernumber}/${cipher.iv}/${cipher.encryptedData}`;
      var newWindow = window.open(path, "_blank");
    };


    return (
      <>
      {redirect && <Redirect to={{pathname: redirectUrl, state: {url: window.location.pathname}}} />}
      {pageState === 0 && <Loading text="Loading Dashboard..." /> }
      {pageState === 1 &&
        <Grid className={classes.root}>
          <Grid container item className={classes.spacing} alignItems="baseline">
            <Grid item xs={4}>
              <span className={classes.refNum}>Order No. <span onClick={handleOrderDetails(ordersData[0])} className={classes.underline}>
                  {ordersData[0]?.ordernumber}
                </span>
              </span>
            </Grid>
            <Grid item xs={8}>
            { isMobile ?
              <MobileOrderTimeLine
                orderStatus={mostRecentOrderStatus}
                holdStatus={ordersData[0]?.holdstatus}
              /> :
              <CombinedOrderTimeLine
                orderStatus={mostRecentOrderStatus}
                statusSteps={mostRecentOrderStatusSteps}
                orderDate={ordersData[0]?.orderdate}
              /> }
          </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction='column'>
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    style={{fontWeight: "bold", marginTop: 15}}
                  >
                    Recent Orders
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Order No.</TableCell>
                          <TableCell>PO Number</TableCell>
                          <TableCell>Part Number</TableCell>
                          <TableCell>Order Date</TableCell>
                          <TableCell>Due Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ordersData.length > 0 ? 
                        ordersData.map((order) =>
                          <OrderPreview order={order} key={order.ordernumber}/>
                        ) : 
                          <TableRow>
                            <TableCell>
                              No Orders Found.
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item>
                  <Button color='secondary' onClick={handleSeeAllOrders}>See all orders</Button>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
      }
      {pageState === 2 &&
        <ErrorPage
          errorCode={500}
          message={`There was an unexpected issue, please refresh the page or contact ${process.env.REACT_SUPPORT_EMAIL}`}
        />
      }
      </>
    );
}
