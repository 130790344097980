import React, { useState, useEffect } from "react";
import { useParams, withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from "notistack";
import { decryptQueryParams } from "../../hooks/cipher";
import { useOktaAuth } from "@okta/okta-react";

import ErrorPage from '../../static/ErrorPage';
import OrderStatusChild from "./OrderStatusChild";
import { RumComponentContextProvider } from '@datadog/rum-react-integration';

function OrderStatus(props){

    const { enqueueSnackbar } = useSnackbar();
    let { ordernumber, iv, encryptedData } = useParams();
    let { releaseid, facility, systemname, isactive } = decryptQueryParams(iv, encryptedData);

    const GET_ORDER_DATA_ENDPOINT = "/user/orders/byOrderId";

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const [order, setOrder] = useState(null);
    const [pageState, setPageState] = useState(-1); // 0 - pulling data | 1 - received valid data | 2 - error

    const formatTime = (date) => {
      return moment(moment.utc(date)).local().format("MMM D, YYYY");
    }

    const formatData = (order) => {
        order['delivery_title'] = 'Estimated Ship Date';
        if (moment(moment.utc(order['duedate'])).local().isBefore()) {
          order['arrival_date'] = 'Under Review'
        } else {
          order['arrival_date'] = formatTime(order['duedate']);
        }
        if (order.shipments !== undefined && order.shipments.length > 0) {
            var trackingInfo = order.shipments[0].tracking_info;
            if (trackingInfo && trackingInfo.status === "delivered") {
                order['delivery_title'] = "Delivered on";
                var deliveryDate = trackingInfo.tracking_details[trackingInfo.tracking_details.length-1].datetime.split("T")[0];
                order['arrival_date'] = formatTime(deliveryDate);
            } else {
                order['delivery_title'] = `Ship Date`;
                order['arrival_date'] = formatTime(order['shipdate']);
            }
        }
        else if (order.ship_date != null) {
          order['delivery_title'] = `Ship Date`;
          order['arrival_date'] = formatTime(order['shipdate']);
        }
        setOrder(order);
    }

    const getOrderData = () => {
        setPageState(0);
        axios.get("/api" + GET_ORDER_DATA_ENDPOINT, {
          params: {
            releaseid: releaseid,
            facility: facility,
            systemname: systemname,
            isactive: isactive
          },
          headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
        })
        .then((res) => {
            if (res.data.length > 0) {
              enqueueSnackbar('Order data loaded successfully', {variant: 'success'});
            }
            var data = res.data;
            if (data) {
              document.title = `Order #${data.ordernumber}`;
              formatData(data);
            } else {
              document.title = 'Order Not Found';
            }
            setPageState(1);
        })
        .catch((err) => {
            enqueueSnackbar('Loading Error', {variant: 'error'});
            if(err.response && err.response.status === 403){
                setPageState(3);
            }
            else {
                setPageState(2);
            }
        });
    }

    useEffect(() => {
      getOrderData();
    }, []);

    return(
        <>
        {pageState === 1 &&
        <RumComponentContextProvider 
          componentName="OrderStatusParent" 
          customAttributes={{
            releaseid: releaseid, 
            facility: facility, 
            systemname: systemname, 
            isactive: isactive
          }}
        >
          <OrderStatusChild order={order}/>
        </RumComponentContextProvider>
        }
        {pageState === 2 && // invalid order data
            <ErrorPage
              errorCode={400}
              message={`There was an issue. Please try again or email ${process.env.REACT_SUPPORT_EMAIL} for help.`}
            />
        }
        {pageState === 3 && // unauthorized
            <ErrorPage
              errorCode={403}
              message={`You do not have access to this order, or it does not exist.`}
            />
        }
        </>
    );
}

export default withRouter(OrderStatus);
