import React, {useState, useEffect} from "react";
import axios from 'axios';
import moment from "moment";
import {useParams, withRouter, useLocation} from 'react-router-dom';
import { useSnackbar } from "notistack";
// import FileDownload from "js-file-download";
import {
    Button,
    ButtonGroup,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    Tooltip,
    Typography,
    TextField,
    Chip,
    Paper,
} from "@material-ui/core";
import OrderPreview from "../../common/OrderPreview";
import Loading from "../../static/Loading";
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ErrorPage from '../../static/ErrorPage';
import { useOktaAuth } from "@okta/okta-react";
import { useRumAction, useRumError } from '@datadog/rum-react-integration';


const useStyles = makeStyles((theme) => ({
    body: {
      width: "80%",
      marginLeft: "10%",
      margin: theme.spacing(3),
    },
    container: {
      maxHeight: 350,
      overflow: 'auto'
    },
    footer: {
      margin: theme.spacing(1),
      marginLeft: "auto",
      marginBottom: "48px"
    },
    spacing: {
      margin: theme.spacing(1),
    }
}));

const GET_ORDERS_BY_CUSTOMER_TEAM_ENDPOINT = "/user/orders";
const GET_ALL_COMPANIES_ENDPOINT = '/system/customers';
const GET_ORDERS_BY_CUSTOMER_ENDPOINT = "/system/orders/byCustomer";
const GET_ORDERS_BY_SALES_REP_ENDPOINT = "/system/orders/bySalesRep";
const GET_USER_INFO_ENDPOINT = '/user/profile';

// Main OrderStatus Component that calls other related components like TimeLine
function CustomerStatus() {

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const currUrl = useLocation().pathname;

    // state variables
    const [orderData, setOrderData] = useState([]);
    const [searchedOrderData, setSearchedOrderData] = useState([]);
    const [filteredOrderData, setFilteredOrderData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({ id: 0, label: ''});
    const [user, setUser] = useState(null);

    const [search, setSearch] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [pageState, setPageState] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    const isSystemAdmin = currUser.groups.includes('SystemAdmin');

    const addAction = useRumAction('Orders');
    const addError = useRumError('Orders');

    const formatTime = (timestamp) => {
      return moment(moment.utc(timestamp)).local().format("MMM D, YYYY");
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    // Set and preserve state of initially fetched orders
    const handleSetState = (data) => {
      setOrderData(data);
      setFilteredOrderData(data);
      setPageState(1);
    }

    // Set orders fetched by search
    const handleSetSearchState = (data) => {
      setSearchedOrderData(data);
      setFilteredOrderData(data);
      setPageState(1);
    }

    // Handle status toggle to filter orders by order status
    const handleFilterStatus = (status) => (e) => {
      if (status === filterStatus) {
        setFilterStatus("");
      } else {
        setFilterStatus(status);
      }
    }

    // Filter fetched orders by status
    useEffect(() => {
      if (filterStatus === "") {
        setFilteredOrderData(orderData);
        return;
      }
      let filtered = orderData.filter((order) => order.orderstatus.toLowerCase() === filterStatus);
      setFilteredOrderData(filtered);
    }, [filterStatus]);

    // Filter fetched orders by search string
    useEffect(() => {
      if (search === "") {
        if (filterStatus === "") {
          setFilteredOrderData(orderData);
        } else {
          let filtered = orderData.filter((order) => order.orderstatus.toLowerCase() === filterStatus);
          setFilteredOrderData(filtered);
        }
        return;
      }
      let filtered = filteredOrderData.filter((order) => {
        let lSearch = search.toLowerCase();
        return (
          order.ordernumber?.toString().toLowerCase().includes(lSearch) ||
          order.ponumber?.toString().toLowerCase().includes(lSearch) ||
          order.partnumber?.toString().toLowerCase().includes(lSearch)
        );
      });
      setFilteredOrderData(filtered);
    }, [search, filterStatus]);

    // Fetch/Search orders placed by users' team members
    const getOrdersByCustomerTeam = (search_string='') => {
      setPageState(0);

      // Fetch first 100 orders, else search database for all matching orders
      const batch_size = search_string.length > 0 ? null : 100;

      axios.get("/api" + GET_ORDERS_BY_CUSTOMER_TEAM_ENDPOINT, {
        params: {
          batch_num: 0,
          batch_size: batch_size,
          search_string: search_string,
        },
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then((res) => {
        let orders = res.data.orders;
        let sortedOrders = orders.sort((a, b) => {
          return moment(b.duedate).diff(moment(a.duedate));
        });
        if (search_string.length > 0) {
          handleSetSearchState(sortedOrders);
        } else {
          handleSetState(sortedOrders);
        }
        enqueueSnackbar('Customer orders loaded successfully', {variant: 'success'});
      })
      .catch((err) => {
        addError('LoadOrders');
        enqueueSnackbar('There was a problem loading customer orders', {variant: 'error'});
        setPageState(2);
        console.error(err);
      });
    }

    // Fetch/Search customer orders
    const getOrdersByCustomer = (customer, search_string='') => {
      setPageState(0);

      // Fetch first 100 orders, else search database for all matching orders
      const batch_size = search_string.length > 0 ? null : 100;

      axios.get("/api" + GET_ORDERS_BY_CUSTOMER_ENDPOINT, {
        params: {
          customer_id: customer.id,
          batch_num: 0,
          batch_size: batch_size,
          search_string: search_string,
        },
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then((res) => {
        let orders = res.data.orders;
        let sortedOrders = orders.sort((a, b) => {
          return moment(b.duedate).diff(moment(a.duedate));
        });
        if (search_string.length > 0) {
          handleSetSearchState(sortedOrders);
        } else {
          handleSetState(sortedOrders);
        }
        enqueueSnackbar('Customer orders loaded successfully', { variant: 'success' });
      })
      .catch((err) => {
        addError('LoadOrders');
        enqueueSnackbar('There was a problem loading customer orders', { variant: 'error' });
        setPageState(2);
        console.error(err);
      });
    }

    // Fetch orders where user is the sales rep
    const getOrdersBySalesRep = (currUser) => {
      axios.get("/api" + GET_ORDERS_BY_SALES_REP_ENDPOINT, {
        params: {
          batch_num: 0,
          batch_size: 100,
          search_string: '',
        },
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then((res) => {
        handleSetState(res.data);
      })
      .catch((err) => {
        addError('LoadOrders');
        setPageState(2);
        console.error(err);
      })
    }

    // Fetch all company names
    const getCompanies = () => {
      axios.get('/api' + GET_ALL_COMPANIES_ENDPOINT, {
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then(res => {
        setCompanies(res.data);
        setPageState(1);
      })
      .catch(err => {
        console.log(err);
        addError('LoadCompanies');
        enqueueSnackbar('There was a problem loading the list of companies, please refresh the page', {variant: 'error'});
        setCompanies([]);
      })
    }

    // Fetch user information from Okta to set customer name
    const getUserInfo = () => {
      axios.get('/api' + GET_USER_INFO_ENDPOINT, {
        headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
      })
      .then(res => {
        setUser(res.data);
      })
      .catch(err => {
          addError('LoadUserInfo');
          console.log("get user info error", err.message);
      });
    };

    // Initially load team's or sales rep's orders
    useEffect(() => {
      document.title = 'Customer Overview';
      getUserInfo();
      if (currUser) {
        if (isSystemAdmin) {
          getOrdersBySalesRep(currUser);
          getCompanies();
        } else {
          getOrdersByCustomerTeam();
        }
      }
    }, [isSystemAdmin]);

    /* Handle search bar event
     * Dynamically filter already fetched orders when search text is entered
     * Fetch all orders from database that match search_string if enter/search button is clicked
     */
    const handleSearch = (e) => {
      if (e.type === "click") {
        isSystemAdmin ? getOrdersByCustomer(selectedCustomer, search) : getOrdersByCustomerTeam(search);
      } else {
        setSearch(e.target.value);
      }
    }

    return (
      <div className={classes.body}>
      {pageState === 0 && <Loading text="Loading Orders..." /> }
      {pageState === 1 && // data received
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" style={{fontWeight: 'bold'}}>
              Orders for {currUser.customerName}
            </Typography>
          </Grid>
          {isSystemAdmin &&  // admin search
            <Grid item xs={12}>
              {companies.length > 0 &&
                <Autocomplete
                    id="combo-box"
                    fullWidth
                    key={selectedCustomer}
                    options={companies}
                    autoSelect={true}
                    renderInput={(params) => <TextField {...params} label="Company Name" variant="outlined"/>}
                    value={selectedCustomer}
                    onChange={(event, newValue) => {
                      setSelectedCustomer(newValue);
                      getOrdersByCustomer(newValue);
                    }}
                />
              }
              {companies.length === 0 &&
                <Loading text='Loading Customers'/>
              }
            </Grid>
          }
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search Orders"
              value={search}
              onChange={handleSearch}
              onKeyPress={handleSearch}
              helperText="Filter by Order #, PO #, Part #, or Status"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Search All Orders">
                    <IconButton type="submit" sx={{ p: '16px' }} aria-label="search" onClick={handleSearch}>
                      <SearchIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                  </Tooltip>
                )
              }}
            />
          </Grid>

          <Grid container item className={classes.spacing}>
            <Grid item xs={6} md={3}>
              <Chip
                variant={filterStatus === "engineering" ? "default" : "outlined"}
                label="Engineering"
                color="secondary"
                onClick={handleFilterStatus("engineering")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Chip
                variant={filterStatus === "production" ? "default" : "outlined"}
                label="In Production"
                color="secondary"
                onClick={handleFilterStatus("production")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Chip
                variant={filterStatus === "shipped" ? "default" : "outlined"}
                label="Shipped"
                color="secondary"
                onClick={handleFilterStatus("shipped")}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Chip
                variant={filterStatus === "completed" ? "default" : "outlined"}
                label="Completed"
                color="secondary"
                onClick={handleFilterStatus("completed")}
              />
            </Grid>
            {/*<Grid item xs={6} md={3}>
              <Chip
                variant={filterStatus === "hold" ? "default" : "outlined"}
                label="On Hold"
                color="primary"
                onClick={handleFilterStatus("hold")}
              />
            </Grid>*/}
          </Grid>
            <TableContainer component={Paper} className={classes.container}>
              <Table stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>Order No.</TableCell>
                    <TableCell>PO Number</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { filteredOrderData.length > 0 &&
                    filteredOrderData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order) =>
                      <OrderPreview order={order} key={order.ordernumber}/>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filteredOrderData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.footer}
            />
            <div className={classes.footer}>{filteredOrderData.length} results found</div>
        </Grid>
      }
      {pageState === 2 && // invalid uniqueOrderId
        <ErrorPage errorCode={500} message={`There was an unexpected issue, please refresh the page or contact ${process.env.REACT_SUPPORT_EMAIL}`}/>
      }
      </div>
    );
}

export default withRouter(CustomerStatus);
