import React, {useState} from 'react';
import { useSnackbar } from "notistack";

import {
    Grid, 
    TextField,
    Button,
    makeStyles,
    Typography
} from '@material-ui/core';
import axios from 'axios';
import { CallMissedSharp, SettingsSystemDaydream } from '@material-ui/icons';
import { useOktaAuth } from "@okta/okta-react";

const GET_USER_INFO_FROM_EMAIL_ENDPOINT = "/system/accounts/byEmail";
const EDIT_USER_TEAM_AND_COMPANY_ENDPOINT = "/system/accounts";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: "5%",
        marginTop: "3%",
        height: "80vh"
    },
    pageHeader: {
        fontSize: "2.5em",
        fontWeight: "bold"
    },
}))

export default function EditContact(){

    const {enqueueSnackbar} = useSnackbar();

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState("");
    const [teamId, setTeamId] = useState("");

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    
    const handleSearchClick = () => {
        axios.get("/api" + GET_USER_INFO_FROM_EMAIL_ENDPOINT, {
            params: {
                email: email,
            },
            headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
        })
        .then(res => {
            setUser(res.data);
            setCompany(res.data.company);
            setTeamId(res.data.teamId)
        })
        .catch(err => {
            enqueueSnackbar(err.response.data, {variant: 'error'});
        })
    }

    const handleSave = () => {
        if(company === "" || teamId === ""){
            enqueueSnackbar("company or team id cannot be empty", {variant: 'error'});
            return;
        }
        if(company.includes("/") || teamId.includes("/")){
            enqueueSnackbar("company and team id cannot contain the '/' character", {variant: 'error'});
            return;
        }
        if(company === user.company && teamId === user.teamId){
            return;
        }
        axios.put("/api" + EDIT_USER_TEAM_AND_COMPANY_ENDPOINT, {
            uid: user.uid,
            teamId: teamId,
            company: company,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            enqueueSnackbar("successfully updated the user team id and company", {variant: 'success'});
        })
        .catch(err => {
            enqueueSnackbar(err.response.data, {variant: 'error'});
        })
    }

    return(
        <div className={classes.root}>
        <Grid container direction='column' spacing={7}>
            <Grid item>
                <Typography className={classes.pageHeader}>Edit user company / team</Typography>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        id="search"
                        name="search"
                        label="Enter email to search for the user"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button color='primary' variant='contained' onClick={handleSearchClick}>Search</Button>
                </Grid>
            </Grid>
            {user !== null &&
            <Grid container item xs={12} direction='column' spacing={3}>
                <Grid item xs={6}>
                    <TextField 
                        id='company'
                        name="company"
                        label="Company"
                        variant="outlined"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        id='teamId'
                        name="teamId"
                        label="Team ID"
                        variant="outlined"
                        value={teamId}
                        onChange={(e) => setTeamId(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Button color='primary' variant='contained' onClick={handleSave}>Save</Button>
                </Grid>
            </Grid>
            }
        </Grid>
        </div>
    );
}