/* DashboardView.tsx
 * This file renders the main dashboard for BomBuilder. When the website is
 * opened to the index endpoint (/), this is rendered. It shows a dashboard
 * of the latest 20 jobs, as well as the ability to search for a past job,
 * or create a new job.
 */

import React, {useEffect} from "react";
import {Switch, useHistory, useLocation} from 'react-router-dom';
import { RumRoute as Route } from '@datadog/rum-react-integration';
import {
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import FooterBar from "./static/FooterBar";
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CustomerStatus from "./components/OrderStatus/CustomerStatus";
import OrderStatusParent from "./components/OrderStatus/OrderStatusParent";
import Admin from "./components/Admin/Admin";
import Toolbar from '@mui/material/Toolbar';
import Settings from "./components/Settings";
import Account from "./components/AccountView/Account";
import Home from "./components/Home";
import HeaderBar from "./static/HeaderBar";
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useOktaAuth } from "@okta/okta-react";
import { datadogRum } from '@datadog/browser-rum';
import { RumComponentContextProvider } from '@datadog/rum-react-integration';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.light
  },
  close: {
    padding: theme.spacing(0.5),
  },
  dashboardHeader: {
    fontSize: 35,
    fontWeight: "bold",
  },
  header: {
    marginBottom: 50,
  },
  dialog: {
    minWidth: 800,
    margin: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(1),
  },
  flows: {
    margin: 30,
  },
  italics: {
    fontStyle: "italic",
  },
  toolbar: {
    background: theme.palette.primary.main
  },
}));

const drawerWidth = 240;

function DashboardView(props) {

  //const isMobile = !useMediaQuery('(min-width:600px)');
  const { pathname } = useLocation();
  let history = useHistory();

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { authState, oktaAuth } = useOktaAuth();
  const currUser = authState?.accessToken?.claims;
  const isSystemAdmin = currUser.groups.includes('SystemAdmin');

  useEffect(() => {
    if (authState) {
      datadogRum.setUser({
        id: authState.accessToken.claims.uid,
        name: authState.idToken.claims.name,
        email: authState.idToken.claims.email,
        company: authState.accessToken.claims.customerName,
        teamid: authState.accessToken.claims.teamid,
        facility: authState.accessToken.claims.facility
      });
    }
  }, [authState]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    document.title = `Summit Portal`;
  });

  const drawer = (
    <div>
      <Toolbar className={classes.toolbar}>
      <a href="/">
        <img style={{marginLeft: '20%'}}
          src={process.env.PUBLIC_URL + '/logo.png'}
          height={50}
          alt='summit logo'
        />
      </a>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button key="Dashboard" selected={pathname === "/"} onClick={() => history.push("/")}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button key="Search" selected={pathname.includes('/customer') || pathname.includes('/orderStatus')} onClick={() => history.push("/customer")}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key="Account" selected={pathname === "/account"} onClick={() => history.push("/account")}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
        <ListItem button key="Notifications" selected={pathname === "/settings"} onClick={() => history.push("/settings")}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
      </List>
      {isSystemAdmin &&
        <>
          <Divider />
          <List>
            <ListItem button key="Admin" selected={pathname === "/admin"} onClick={() => history.push("/admin")}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin Settings" />
            </ListItem>
          </List>
        </>
      }
    </div>
  );

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <HeaderBar onClick={handleDrawerToggle} mobileOpen={mobileOpen}/>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ backgroundColor: '#F7F7F7', flexGrow: 1,  width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Switch>
            <RumComponentContextProvider
              componentName="DashboardView"
            >
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path="/customer">
              <CustomerStatus />
            </Route>
            <Route path="/settings">
              <Settings/>
            </Route>
            <Route path="/account">
              <Account/>
            </Route>
            <Route path="/orderStatus/:order_number/:iv/:encryptedData">
              <OrderStatusParent />
            </Route>
            <Route path="/admin">
              <Admin/>
            </Route>
            </RumComponentContextProvider>
          </Switch>
        </Box>
        <AppBar
          position="fixed"
          sx={{
            top: 'auto',
            bottom: 0,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <FooterBar />
        </AppBar>
      </Box>
  );
}

export default DashboardView;
