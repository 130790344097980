import React, {useState, useEffect, useRef} from "react";
import axios from 'axios';
import moment from "moment";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import {
    makeStyles,
    Box,
    Card,
    CardContent,
    Grid,
    Typography
} from "@material-ui/core";

import SpecificationsTable from './SpecificationsTable';
import SalesRep from './SalesRep';
import JobType from './JobType';
import OverviewCard from './OverviewCard';
import CustomerFiles from './CustomerFiles';
import DeliveryTimeLine from '../components/OrderStatus/DeliveryTimeLine';
import { useOktaAuth } from "@okta/okta-react";
import { RumComponentContextProvider } from '@datadog/rum-react-integration';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const MAPBOX_GEOCODE_ENDPOINT = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '1%'
    },
    title: {
      fontSize: 24,
      color: '#1B192B',
      margin: theme.spacing(1),
    },
    subtitle: {
      fontSize: 12,
      color: '#1B192B',
      margin: theme.spacing(1),
    },
    mapContainerCSS: {
      height: '200px',
      marginBottom: '5px',
      borderRadius: '5px'
    },
}));

export default function OrderOverview(props){

    var classes = useStyles();
    const { orderData } = props;

    // Map info
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);


    var addressForMap = orderData.address1 + "," + orderData.address2 + "," + orderData.city + "," + orderData.state + "," + orderData.zip + "," + orderData.country;
    var totalLeadtime = orderData.turntime ?? 0;
    const leadTimeFormatted = `${totalLeadtime} day${totalLeadtime > 1 ? 's' : ''}`;

    const formatTime = (timestamp) => {
      return moment(moment.utc(timestamp)).local().format("MMM D, YYYY");
    }

    const formatCurrency = (val, currency) => {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      });

      return formatter.format(val);
    }

    const jobTypes = React.useMemo(() => {
        return orderData.jobtype.split(",");
    }, [orderData.job_type]);

    const geocode = async() => {
      axios.get(MAPBOX_GEOCODE_ENDPOINT + addressForMap + '.json?access_token=' + process.env.REACT_APP_MAPBOX_TOKEN)
      .then(res => {
        const data = res.data;
        if(data.features.length > 0){
          setLng(data.features[0].center[0]);
          setLat(data.features[0].center[1]);
          map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [data.features[0].center[0], data.features[0].center[1]],
            attributionControl: false,
            zoom: 15,
            interactive: false
          });
          const marker1 = new mapboxgl.Marker({color: "#1E58F9", draggable: false})
                .setLngLat([data.features[0].center[0], data.features[0].center[1]])
                .addTo(map.current);
        }
      })
      .catch(err => {
        console.log('error in geocode', err);
      })
    }

    useEffect(() => {
      if (map.current) return; // initialize map only once
      if (mapContainer.current !== null) geocode();
    }, [mapContainer.current]);

    return (
      <Grid container className={classes.root} spacing={3} alignItems='stretch'>
        {/* horizontal container for 4 cards */}
        <Grid container item xs={12} md={6}>
          <Typography className={classes.title}>
            Order Overview
          </Typography>
          <Grid container spacing={1} alignItems='stretch'>
            <Grid item xs={6}>
                <OverviewCard
                  img='/qty-icon.svg'
                  val={orderData.quantity}
                  name="Quantity"
                />
              </Grid>
            <Grid item xs={6}>
              <OverviewCard
                img='/leadtime-icon.svg'
                val={leadTimeFormatted}
                name="Lead Time"
              />
            </Grid>
            <Grid item xs={6}>
              <OverviewCard
                img='/unit-icon.svg'
                val={formatTime(orderData.orderdate)}
                name="Order Placed"
              />
            </Grid>
            <Grid item xs={6}>
              <OverviewCard
                img='/price-icon.svg'
                val={formatCurrency(orderData.totalcost, orderData.currency)}
                name="Total Price"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Shipping address */}
        <Grid item xs={12} md={6}>
          <Typography className={classes.title}>
            Shipping
          </Typography>
          <Typography className={classes.subtitle}>
            You can find all shipment updates here
          </Typography>
          {(orderData.shipments === undefined || orderData.shipments?.length === 0) &&
            <Card>
              <CardContent>
              <div ref={mapContainer} className={classes.mapContainerCSS} />
                <Box
                >
                  {orderData.address1 && (
                    <Typography>
                      {orderData.address1.trim()}
                    </Typography>
                  )}
                  {orderData.address2 && (
                    <Typography>
                      {orderData.address2.trim()}
                    </Typography>
                  )}
                  {orderData.city && (
                    <Typography>
                      {orderData.city.trim()}
                    </Typography>
                  )}
                  {orderData.state && (
                    <Typography>
                      {orderData.state.trim()}
                    </Typography>
                  )}
                  {orderData.zip && (
                    <Typography>
                      {orderData.zip.trim()}
                    </Typography>
                  )}
                  {orderData.country && (
                    <Typography>
                      {orderData.country.trim()}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          }
          {orderData.shipments && orderData.shipments.length > 0 &&
            <DeliveryTimeLine
              shipments={orderData.shipments ?? {}}
            />
          }
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>
            Job Type
          </Typography>
          <Typography className={classes.subtitle}>
            This is the job type you have selected for this order
          </Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            {jobTypes.includes("Boards") && 
            <Grid item xs={12} md={4}>
              <JobType name="PCB" img="fab-icon.svg" active={jobTypes.includes("Boards")}/>
            </Grid>
            }
            {jobTypes.includes("Assembly") &&
            <Grid item xs={12} md={4}>
              <JobType name="Assembly" img="assembly-icon.svg" active={jobTypes.includes("Assembly")}/>
            </Grid>
            }
            {jobTypes.includes("Parts") &&
            <Grid item xs={12} md={4}>
              <JobType name="Components" img="turnkey-icon.svg" active={jobTypes.includes("Parts")}/>
            </Grid>
            }
          </Grid>
        </Grid>

        {/* Specifications Table */}
        <Grid item xs={12}>
          <Typography className={classes.title}>
            Design Specs
          </Typography>
          <SpecificationsTable data={orderData}/>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>
            Relevant Files
          </Typography>
          <Typography className={classes.subtitle}>
            Use this area to upload miscellanous files tied to this specific order. File uploads will be monitored and are subject to approval. 
          </Typography>
          <Typography className={classes.subtitle}>
            All files are stored in an ITAR-Compliant server on Azure Government Cloud.
          </Typography>
          {/* showFileType here shows the internal / external files column */}
          <RumComponentContextProvider
            componentName="CommonOrderOverview" 
          >
            <CustomerFiles allItar={true} orderData={orderData}/>
          </RumComponentContextProvider>
        </Grid>
      </Grid>
    );
}
