import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useOktaAuth } from "@okta/okta-react";
import { useRumAction, useRumError } from '@datadog/rum-react-integration';

import {
    Grid,
    TextField,
    Button,
    makeStyles,
    Paper,
    Tooltip,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Typography,
    CircularProgress
} from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';

const ADD_TEAM_MEMBER_ENDPOINT = '/admin/teams/invites';
const REMOVE_INVITED_TEAM_MEMBER_ENDPOINT = '/admin/teams/invites/remove';

const useStyles = makeStyles((theme) => ({
    addMember: {
        marginLeft: "10%"
    },
    sectionHeader: {
      fontSize: 24,
      marginTop: 10,
      marginBottom: 10,
    }
}));

export default function AddTeamMembers(props){

    var classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [invitedMembers, setInvitedMembers] = useState(props.invitedMembers);
    const [memberEmail, setMemberEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const { authState, oktaAuth } = useOktaAuth();

    const addAction = useRumAction("AddTeamMembers");
    const addError = useRumError("AddTeamMembers");

    React.useEffect(() => {
      setInvitedMembers(props.invitedMembers);
    }, [props.invitedMembers]);

    const handleRemoveMember = (index) => {
        if(window.confirm("Are you sure you want to remove this invite?")){
            axios.delete('/api' + REMOVE_INVITED_TEAM_MEMBER_ENDPOINT, {
              data: {
                removeMemberEmailId: invitedMembers[index].email
              },
              headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
            })
            .then(res => {
              var members = invitedMembers;
              members.splice(index, 1);
              setInvitedMembers([...members]);
              addAction('RemoveTeamMember');
              enqueueSnackbar("Invite removed successfully", {variant: 'success'});
            })
            .catch(err => {
                addError('RemoveTeamMember');
                if(err.response){
                    enqueueSnackbar("There was an error, try again", {variant: 'error'});
                }
            })
        }
    }

    const handleAddMemberClick = () => {
        setLoading(true);
        axios.post('/api' + ADD_TEAM_MEMBER_ENDPOINT,
          { emailId: memberEmail },
          { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, }
        )
        .then(res => {
            setLoading(false);
            enqueueSnackbar("An invitation email has been sent to the requested email", {variant: 'success'});
            setMemberEmail("");
            setInvitedMembers([
              ...invitedMembers,
              { email: memberEmail }
            ]);
            addAction('InviteTeamMember');
        })
        .catch(err => {
            addError('InviteTeamMember');
            setLoading(false);
            if(err.response){
                enqueueSnackbar(err.response.data.message, {variant: 'error'});
            }
        })
    }

    const handleAddMemberTextFieldChange = (e) => {
      setMemberEmail(e.target.value);
    }

    return (
      <Grid container spacing={3} direction='column'>
        {invitedMembers.length > 0 && (
          <Grid item>
            <Typography className={classes.sectionHeader}>Invited Team Members</Typography>
            <TableContainer component={Paper} className={classes.container}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invitedMembers.map((member, index) => {
                    return (
                      <TableRow key={member.email}>
                        <TableCell>{member.email}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Cancel Invite">
                            <Button
                              onClick={() => handleRemoveMember(index)}
                            >
                              <Cancel style={{color: 'red'}}/>
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        <Grid item>
          <Typography className={classes.sectionHeader}>
            Invite Team Member
          </Typography>
        </Grid>
        <Grid item>
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="member-email"
                      label="Email"
                      onChange={handleAddMemberTextFieldChange}
                      value={memberEmail}
                      variant="outlined"
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                      disabled={memberEmail.length === 0}
                      onClick={handleAddMemberClick}
                      color="primary"
                      variant="outlined"
                      className={classes.addMember}
                    >
                      Send Invite
                    </Button>
                </Grid>
                {loading &&
                  <Grid item xs={3}>
                      <CircularProgress color='secondary'/>
                  </Grid>
                }
            </Grid>
        </Grid>
      </Grid>
    );
}
