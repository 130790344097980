import React from "react";
import moment from "moment";
import {
    makeStyles,
    Button,
    TableCell,
    TableRow,
    Tooltip
} from "@material-ui/core";
import { encryptQueryParams } from "../hooks/cipher";

//import Skeleton from 'react-loading-skeleton';
//import 'react-loading-skeleton/dist/skeleton.css';

const useStyles = makeStyles((theme) => ({
    holdRow: {
      outline: 'none',
      borderColor: 'red',
      boxShadow: '0 0 15px red',
    },
}));

export default function OrderPreview(props){

    var classes = useStyles();
    var orderData = props.order;

    const formatTime = (timestamp) => {
      if (timestamp == null || timestamp == undefined) {
        return null;
      }
      return moment(moment.utc(timestamp)).local().format("M/D/YYYY");
    }

    const handleOrderDetails = () => {
      let cipher = encryptQueryParams({
        releaseid: orderData.releaseid,
        facility: orderData.facility,
        systemname: orderData.systemname,
        isactive: orderData.isactive
      });
      let path = `orderStatus/${orderData.ordernumber}/${cipher.iv}/${cipher.encryptedData}`;
      var newWindow = window.open(path, "_blank");
    };

    return (
      <TableRow
        className={orderData.orderstatus === 'On Hold' ? classes.holdRow : null}
      >
        <TableCell>
          {orderData.ordernumber}
        </TableCell>
        <TableCell>
          {orderData.ponumber}
        </TableCell>
        <TableCell>
          {orderData.partnumber}{orderData.partrevision == null ? "" : `-` + orderData.partrevision}
        </TableCell>
        <TableCell>
          {formatTime(orderData.orderdate)}
        </TableCell>
        <TableCell>
          {formatTime(orderData.duedate)}
        </TableCell>
        <TableCell>
          {orderData.orderstatus}
        </TableCell>
        <TableCell>
          <Tooltip title="View Order Details">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOrderDetails}
            >
              Go
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
}
