import crypto from 'crypto';

const CIPHER_ENCRYPTION_KEY = process.env.REACT_APP_CIPHER_ENCRYPTION_KEY
const cipher_algorithm = "aes-256-cbc";
const cipher_key = Buffer.from(CIPHER_ENCRYPTION_KEY, 'hex');

const createCipher = () => {
  let iv = crypto.randomBytes(16);
  let cipher = crypto.createCipheriv(cipher_algorithm, cipher_key, iv);
  return { iv: iv, cipher: cipher};
}

const createDecipher = (iv) => {
  return crypto.createDecipheriv(cipher_algorithm, cipher_key, iv);
}

export const encryptQueryParams = (queryParams) => {
  // Creating Cipher
  let { iv, cipher } = createCipher();

  // Format text
  let text = JSON.stringify(queryParams);

  // Updating encrypted text
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);

  // returns data after encryption and random iv string
  return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
}

export const decryptQueryParams = (iv_string, encryptedData_string) => {
  let iv = Buffer.from(iv_string, 'hex');
  let encryptedText = Buffer.from(encryptedData_string, 'hex');

  // Creating Decipher
  let decipher = createDecipher(iv);

  // Updating decrypted text
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);

  // returns data after decryption
  return JSON.parse(decrypted.toString());
}
