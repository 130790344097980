import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from "@okta/okta-react";
import axios from 'axios';

import {
    makeStyles,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    TextField,
    Checkbox,
    Tooltip
} from '@material-ui/core';

import {
    FeaturedPlayListOutlined,
    HighlightOff
} from '@material-ui/icons'

const GET_NEW_FEATURES = '/system/newfeatures';
const UPDATE_NEW_FEATURES = '/admin/updateNewFeatures';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        marginLeft: "10%",
        minHeight: "100vh",
        marginBottom: "3%"
    },
}));


export default function ListNewFeaturesForPopup(){

    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [newFeatures, setNewFeatures] = useState([]);
    const [feature, setFeature] = useState("");
    const [forcePopupChecked, setForcePopupChecked] = useState(false);

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    
    useEffect(() => {
        axios.get('/api' + GET_NEW_FEATURES, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            setNewFeatures(res.data);
        })
        .catch(err => {
            enqueueSnackbar('There was an error getting the new features, please reload the page', {variant: 'error'});
        })
    }, []);

    const deleteFeature = (index) => {
        var arr = newFeatures;
        arr.splice(index, 1);
        setNewFeatures([...arr]);
    }

    const addNewFeature = () => {
        var arr = newFeatures;
        arr.push(feature);
        setNewFeatures(arr);
        setFeature("");
    }

    const updateFeatures = () => {
        axios.post('/api' + UPDATE_NEW_FEATURES, {
            newFeatures: newFeatures,
            forcePopup: forcePopupChecked,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            enqueueSnackbar('New features updated', {variant :'success'});
            setForcePopupChecked(false);
        })
        .catch(e => {
            enqueueSnackbar('There was a problem updating the features', {variant :'error'});
        })
    }

    return(
        <Grid container direction='column' className={classes.root} spacing={3}>
            <Grid item>
                <h2>Add new Features to be listed in popup</h2>
            </Grid>
            <Grid item>
                <Typography>The features listed below will be displayed to all users when they login depending on their preferences</Typography>
            </Grid>
            <Grid item>
                <Button onClick={() => setNewFeatures([])} variant='contained' style={{backgroundColor: 'red', color: 'white'}}>Clear All</Button>
            </Grid>
            <Grid item container direction='column' spacing={3}>
                {newFeatures.map((value, index) => (
                    <Grid item key={value}>
                        <Card>
                            <CardContent>
                                <Grid container alignItems='center'>
                                    <Grid item xs={12} md={10}>{value}</Grid>
                                    <Grid item xs={12} md={2}>
                                        <Tooltip title="Remove">
                                            <Button onClick={() => deleteFeature(index)}>
                                                <HighlightOff style={{color: 'red'}}/>
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container alignItems='center' spacing={3}>
                                <Grid item xs={12} md={9}>
                                    <TextField 
                                        id="newFeature"
                                        name="nreFeature"
                                        label="New Feature"
                                        variant="outlined"
                                        value={feature}
                                        onChange={(e) => setFeature(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}><Button onClick={addNewFeature} variant='contained' color='secondary'>Add</Button></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item container alignItems='center'>
                <Grid item>
                    <Checkbox checked={forcePopupChecked} onChange={(e) => setForcePopupChecked(e.target.checked)}/>
                </Grid>
                <Grid item>
                    <Typography>Force Popup for all users</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Button onClick={updateFeatures} color='primary' variant='contained'>Save</Button>
            </Grid>
        </Grid>
    );
}