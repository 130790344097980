import React, {useState, useEffect} from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from "@okta/okta-react";

import CustomerFiles from '../../common/CustomerFiles';

import {
    makeStyles,
    Grid,
    Typography,
    Button,
    TextField,
} from '@material-ui/core';

import {
    Cancel
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        marginLeft: "10%",
        minHeight: "100vh",
        marginBottom: "3%"
    },
    refNumTextField: {
        // width: "50%"
    },
    fileName: {
        width: "100%",
    },
    fileTypeSelection: {
        width: "50%"
    },
    subheader: {
        fontWeight: "bold"
    }
}));

const GET_FILES_FOR_REFERENCE_NUMBER_ENDPOINT = '/system/files';

export default function FileUpload(props){

    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const [referenceNumber, setReferenceNumber] = useState("");
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(-1);
    const [isItar, setIsItar] = useState(false);
    const [filesForReferenceNumber, setFilesForReferenceNumber] = useState([]);
    const [searchedReferenceNumber, setSearchedReferenceNumber] = useState("");
    const [showFilesTable, setShowFilesTable] = useState(false);
    
    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;
    
    const fileTypes = {
        0: 'Certificate of compliance',
        1: 'Testing Docs',
        2: 'Gerber image file',
        3: 'DFM report pdf'
    }

    const fileInputRef = React.useRef(null);

    const handleReferenceNumberSearch = () => {
        if(referenceNumber === ""){
            alert("Please enter a reference number");
            return;
        }
        setShowFilesTable(false);
        setSearchedReferenceNumber(referenceNumber);
        axios.get('/api' + GET_FILES_FOR_REFERENCE_NUMBER_ENDPOINT, {
            params: {
                referenceNumber: referenceNumber,
            },
            headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
        })
        .then(res => {
            setFilesForReferenceNumber(res.data);
            setShowFilesTable(true);
        })
        .catch(err => {
            enqueueSnackbar('There was a problem getting the data. Please try again', {variant: 'error'})
        })
    }

    return(
        <Grid container direction='column' className={classes.root} spacing={3}>
            <Grid item>
                <h2>View Files / Add new Files</h2>
            </Grid>
            <Grid item>
                <Typography className={classes.subheader}>Search for files associated with an order</Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        className={classes.refNumTextField}
                        variant='outlined'
                        fullWidth
                        label='Enter the reference number of the order *'
                        onChange={(e) => setReferenceNumber(e.target.value)}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter'){
                                handleReferenceNumberSearch();
                            }
                        }}
                        value={referenceNumber}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleReferenceNumberSearch}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
            {showFilesTable &&
            <>
                <Grid item>
                    <Typography>Existing files for Reference Number: <b>{searchedReferenceNumber}</b></Typography>
                </Grid>
                <Grid item container xs={12}>
                    <CustomerFiles referenceNumber={searchedReferenceNumber} user={currUser} adminView/>
                </Grid>
            </>
            }
        </Grid>
    );
}