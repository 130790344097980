import React, {useState, useEffect, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import TeamMembers from './TeamMembers';
import AddTeamMembers from './AddTeamMembers';
import Loading from '../../static/Loading';
import BackGround from '../../static/BackGround';
import { useOktaAuth } from "@okta/okta-react";
import { RumComponentContextProvider, useRumAction, useRumError } from '@datadog/rum-react-integration';

import{
  Grid,
  Button,
  TextField,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Typography
}  from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "90%",
      marginLeft: "5%",
      marginBottom: '5%'
    },
    displayName: {
      fontSize: 36,
      fontWeight: "bold"
    },
    companyName: {
      fontSize: 24,
    },
    avatar: {
      height: 50,
      width: 50,
      fontSize: 36,
      backgroundColor: theme.palette.secondary.main
    },
    sectionHeader: {
      fontSize: 32,
      marginTop: 10,
      marginBottom: 10,
    },
    sectionSubheader: {
      fontSize: 20,
      fontWeight: 550,
      marginBottom: 20,
    },
    avatarHeader: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
}));

const GET_TEAM_MEMBERS_ENDPOINT = '/user/teams';
const GET_INVITED_TEAM_MEMBERS_ENDPOINT = '/admin/teams/invites';
const GET_USER_INFO_ENDPOINT = '/user/profile';
const GET_USER_INFO_FROM_DB_ENDPOINT = '/user/info';
const CHANGE_NAME_ENDPOINT = '/user/profile/username';

export default function Account(){

  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();

  const { authState, oktaAuth } = useOktaAuth();
  const currUser = authState?.accessToken?.claims;

  const addAction = useRumAction("Account");
  const addError = useRumError("Account");

  const [user, setUser] = useState(null);
  const [dbUser, setDbUser] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [firstNameInput, setFirstNameInput] = useState(null);
  const [lastNameInput, setLastNameInput ] = useState(null);
  const [isTeamAdmin, setIsTeamAdmin] = useState(false);

  const [allMembers, setAllMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);

  const handleChangeName = () => {
    if (firstNameInput == null || lastNameInput == null) {
      enqueueSnackbar('Fields cannot be blank', {variant: 'error'});
      return;
    }
    axios.put('/api' + CHANGE_NAME_ENDPOINT, {
      firstName: firstNameInput,
      lastName: lastNameInput,
    },
    {
      headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
    })
    .then(res => {
      addAction('AccountInfoChange');
      setFirstName(firstNameInput);
      setLastName(lastNameInput);
      toggleChangeNameDialog();
      enqueueSnackbar('Successfully changed name', {variant: 'success'});
    })
    .catch(err => {
      addError('AccountInfoChange');
      console.log("change name error", err.message);
      enqueueSnackbar('There was an error while changing your name', {variant: 'error'});
    });
  }

  const handleResetPassword = () => {
    oktaAuth.forgotPassword({
      username: user.email,
      factorType: 'EMAIL',
    })
    .then((transaction) => {
      addAction('ResetPassword');
      enqueueSnackbar('Successfully sent password reset email', {variant: 'success'});
    })
    .catch(function(err) {
      addError('ResetPassword');
      enqueueSnackbar('There was an issue', {variant: 'error'})
    });
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const getTeamMembersTable = () => {
    axios.get('/api' + GET_TEAM_MEMBERS_ENDPOINT, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
    .then(res => {
      setAllMembers(res.data);
    })
    .catch(err => {
        addError('LoadTeamMembers');
        console.log("get team members error", err.message);
    });
  };

  React.useEffect(() => {
    if (isTeamAdmin) {
      axios.get('/api' + GET_INVITED_TEAM_MEMBERS_ENDPOINT, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
      .then(res => {
        setInvitedMembers(res.data);
      })
      .catch(err => {
        addError('LoadInvitedMembers');
        console.log("Get invited members error");
      });
    };
  }, [isTeamAdmin]);

  const getUserInfo = () => {
    axios.get('/api' + GET_USER_INFO_ENDPOINT, {
      headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
    })
    .then(res => {
      setUser(res.data);
      setIsTeamAdmin(res.data.teamAdmin);
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
    })
    .catch(err => {
        addError('LoadUserInfo');
        console.log("get user info error", err.message);
    });

    axios.get('/api' + GET_USER_INFO_FROM_DB_ENDPOINT, {
      headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
    })
    .then(res => {
      setDbUser(res.data);
    })
    .catch(err => {
        addError('LoadDBUserInfo');
        console.log("get user info from db error", err.message);
    });
  };

  const toggleChangeNameDialog = () => {
    setChangeNameDialogOpen(!changeNameDialogOpen);
  }

  useEffect(() => {
    if(currUser) {
      getUserInfo();
      getTeamMembersTable();
    }
  }, []);

  return(
    <>
    {user && 
      <BackGround showVector={false} height={-100}>
      <Grid container direction='column' spacing={5} className={classes.root}>
        <Grid item style={{marginTop: 70}}>
          <Grid container spacing={3} direction="row" alignItems="center" wrap="nowrap">
            <Grid item>
              <Typography className={classes.displayName}>{`${firstName ?? ""} ${lastName ?? ""}`}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.companyName}>{`${user.customerName?? ""}`}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Typography className={classes.sectionHeader}>Contact Information</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Email"
                variant="outlined"
                disabled
                fullWidth
                value={user.email}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Phone"
                variant="outlined"
                disabled
                fullWidth
                value={user.phone ?? ""}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Company"
                variant="outlined"
                disabled
                fullWidth
                value={user.customerName}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Button onClick={toggleChangeNameDialog} variant="outlined" color="secondary">Change name</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleResetPassword} variant="outlined" color="secondary">Reset password</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <RumComponentContextProvider
            componentName='Account'
          >
          <TeamMembers user={user} members={allMembers} canviewallorders={dbUser ? dbUser.canviewallorders : false} />
          {user.teamAdmin && (<AddTeamMembers user={user} invitedMembers={invitedMembers}/>)}
          </RumComponentContextProvider>
        </Grid>

      </Grid>

      <Dialog open={changeNameDialogOpen} onClose={toggleChangeNameDialog}>
        <DialogTitle>Change your name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your first and last name below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            fullWidth
            variant="standard"
            onInput={e => setFirstNameInput(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Last Name"
            fullWidth
            variant="standard"
            onInput={e => setLastNameInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleChangeNameDialog}>Cancel</Button>
          <Button onClick={handleChangeName}>Submit</Button>
        </DialogActions>
      </Dialog>
      </BackGround>
    }
    {!user && <Loading text="Loading Account..." /> }
    </>
  );
}
