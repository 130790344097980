import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import MuiPhoneNumber from "material-ui-phone-number";
import Loading from '../static/Loading';
import { useRumAction, useRumError } from '@datadog/rum-react-integration';

import {
    Grid,
    Switch,
    makeStyles,
    Typography,
    TextField,
    Button
} from '@material-ui/core';

import{
    Cancel,
    Save,
    Edit,
    ChangeCircleRounded,
    FactCheckRounded,
    LocalShippingRounded
} from '@mui/icons-material';
import { useOktaAuth } from "@okta/okta-react";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "95%",
        marginLeft: "5%",
        marginTop: "3%"
    },
    pageTitle: {
        fontSize: "3em",
        fontWeight: "bold"
    },
    sectionTitle: {
        fontSize: "2em",
        fontWeight: "bold"
    },
    editContainers: {
        marginTop: "2%"
    },
    notificationTypesHeader: {
        fontSize: "1.5em",
        fontWeight: "bold"
    }
}));

export default function Settings(props){

    const GET_NOTIFICATION_INFO_ENDPOINT = '/user/notifications';
    const SAVE_NEW_NOTIFICATION_PHONE_ENDPOINT = '/user/notifications/contact/phone';
    const SAVE_TEXT_NOTIFICATION_PREFERENCE_ENDPOINT = '/user/notifications/pref/phone';
    const SAVE_NEW_NOTIFICATION_EMAIL_ENDPOINT = '/user/notifications/contact/email';
    const SAVE_EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT = '/user/notifications/pref/email';

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const addAction = useRumAction("Settings");
    const addError = useRumError("Settings");

    const [textNotificationsSwitchChecked, setTextNotificationsSwitchChecked] = useState(false);
    const [emailNotificationsSwitchChecked, setEmailNotificationsSwitchChecked] = useState(false);
    const [textNotificationTextBoxEnabled, setTextNotificationTextBoxEnabled] = useState(false);
    const [emailNotificationTextBoxEnabled, setEmailNotificationTextBoxEnabled] = useState(false);
    const [notificationPhone, setNotificationPhone] = useState('');
    const [notificationPhoneTextBoxValue, setNotificationPhoneTextBoxValue] = useState('');
    const [notificationEmail, setNotificationEmail] = useState('');
    const [notificationEmailTextBoxValue, setNotificationEmailTextBoxValue] = useState('');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("/login");
    const [pageState, setPageState] = useState(0);

    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const handleTextNotificationsSwitchChange = (e) => {
        const checked = e.target.checked;
        setTextNotificationsSwitchChecked(checked);
        axios.put("/api" + SAVE_TEXT_NOTIFICATION_PREFERENCE_ENDPOINT, {
            receiveTextNotifications: checked,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            addAction(`NotificationPreferenceTurned ${checked ? 'On' : 'Off'}`, {
              'type': 'text'
            });
            enqueueSnackbar(`Text notifications turned ${(checked) ? 'on' : 'off'}`, {variant: 'success'});
        })
        .catch(err => {
            addError('NotificationChange', {
              'type': 'text'
            });
            setTextNotificationsSwitchChecked(err.response.data);
            enqueueSnackbar('There was a problem setting the text notification preference, please try again', {variant: 'error'});
        })
    }

    const handleEmailNotificationsSwitchChange = (e) => {
        setEmailNotificationsSwitchChecked(e.target.checked);
        const checked = e.target.checked;
        setEmailNotificationsSwitchChecked(checked);
        axios.put("/api" + SAVE_EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT, {
            receiveEmailNotifications: checked,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            addAction(`NotificationPreferenceTurned ${checked ? 'On' : 'Off'}`, {
              'type': 'email'
            });
            enqueueSnackbar(`Email notifications turned ${(checked) ? 'on' : 'off'}`, {variant: 'success'});
        })
        .catch(err => {
            addError('NotificationChange', {
              'type': 'email'
            });
            setEmailNotificationsSwitchChecked(err.response.data);
            enqueueSnackbar('There was a problem setting the email notification preference, please try again', {variant: 'error'});
        })
    }

    const handlePhoneNotificationCancelEdit = () => {
        setNotificationPhoneTextBoxValue(notificationPhone);
        setTextNotificationTextBoxEnabled(false);
    }

    const handleEmailNotificationCancelEdit = () => {
        setNotificationEmailTextBoxValue(notificationEmail);
        setEmailNotificationTextBoxEnabled(false);
    }

    const handlePhoneNotificationEditSave = () => {
        if (!validPhoneNumber) {
          enqueueSnackbar('Please enter a valid US phone number', {variant: 'warning'});
          return;
        }
        let saveNotificationPhoneValue = validatePhoneNumber(notificationPhoneTextBoxValue, true);
        axios.put("/api" + SAVE_NEW_NOTIFICATION_PHONE_ENDPOINT, {
            phoneNumber: saveNotificationPhoneValue,
        }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
        .then(res => {
            addAction('NotificationInfoChanged');
            enqueueSnackbar(`Notification phone number changed to ${notificationPhoneTextBoxValue}`, {variant: 'success'});
            setNotificationPhone(notificationPhoneTextBoxValue);
            setTextNotificationTextBoxEnabled(false);
        })
        .catch(err => {
            addError('NotificationChange');
            setNotificationPhoneTextBoxValue(notificationPhone);
            enqueueSnackbar('There was an error trying to save the new number, please try again.', {variant: 'error'});
        })
    }

    const handleEmailNotificationEditSave = () => {
        if(!validateEmail(notificationEmailTextBoxValue)) return;
        else {
          axios.put("/api" + SAVE_NEW_NOTIFICATION_EMAIL_ENDPOINT, {
              email: notificationEmailTextBoxValue,
          }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
          .then(res => {
              addAction('NotificationInfoChanged');
              enqueueSnackbar(`Notification Email changed to ${notificationEmailTextBoxValue}`, {variant: 'success'});
              setNotificationEmail(res.data);
              setEmailNotificationTextBoxEnabled(false);
          })
          .catch(err => {
              addError('NotificationChange');
              setNotificationEmailTextBoxValue(notificationEmail);
              enqueueSnackbar('There was an error trying to save the new email, please try again.', {variant: 'error'});
          })
        }
    }

    const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
        return true;
    }
        enqueueSnackbar('You have entered an invalid email address!', {variant: 'warning'});
        return false;
    }

    const validatePhoneNumber = (value, save=false) => {
      // Filter only numbers from the input
      let cleaned = ('' + value).replace(/\D/g, '');

      // Match input to correct number pattern
      let match = cleaned.match(/^(1)(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        setValidPhoneNumber(true);
        // Returns formatted phone number for displaying or simple unformatted number for saving in database
        return save ? [match[2], match[3], match[4]].join('') : ['+1', '(', match[2], ') ', match[3], '-', match[4]].join('');
      } else {
        setValidPhoneNumber(false);
        return "";
      }
    }

    React.useEffect(() => {
      setNotificationPhoneTextBoxValue(validatePhoneNumber(notificationPhone));
    }, [notificationPhone]);

    React.useEffect(() => {
      setNotificationEmailTextBoxValue(notificationEmail);
    }, [notificationEmail]);

    const handleTextNotificationTextBoxValueChange = (value) => {
      setNotificationPhoneTextBoxValue(validatePhoneNumber(value));
    }

    const handleEmailNotificationTextBoxValueChange = (e) => {
      setNotificationEmailTextBoxValue(e.target.value);
    }

    useEffect(() => {
        if (currUser) {
            axios.get("/api" + GET_NOTIFICATION_INFO_ENDPOINT, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
            .then(res => {
                const data = res.data;
                setNotificationPhone("+1" + data.notificationPhone);
                setNotificationEmail(data.notificationEmail);
                setTextNotificationsSwitchChecked(data.receiveTextNotifications);
                setEmailNotificationsSwitchChecked(data.receiveEmailNotifications);
                setPageState(1);
            })
            .catch(err => {
                enqueueSnackbar('There was a problem getting the data. Please refresh the page', {variant: 'error'});
            })
        }
        else{
            setRedirectUrl("/login");
            setRedirect(true);
        }
    }, []);

    const notificationTypes = [{
            icon: <FactCheckRounded sx={{fontSize: 50}} color='success'/>,
            title: 'Order Confirmation',
            description: "You'll be notified whenever any of your orders are confirmed."
        },
        {
            icon: <ChangeCircleRounded sx={{fontSize: 50}} color='info'/>,
            title: 'Order Status Change',
            description: "You'll be notified whenever there is any change to the status of your order. (ex. In Production to Shipped)"
        },
        {
            icon: <LocalShippingRounded sx={{fontSize: 50}} color='info' />,
            title: 'Ship Date Change',
            description: "You'll be notified whenever the shipping date of any of your orders changes."
        }
    ]

    return(
        <>
        {redirect && <Redirect to={redirectUrl} />}
        {pageState === 0 && <Loading text="Loading Settings..." /> }
        {pageState === 1 &&
        <Grid container className={classes.root} spacing={3}>
            <Grid item container direction='column' spacing={5} xs={12} lg={8}>
                <Grid item>
                    <Typography className={classes.pageTitle}>Settings</Typography>
                </Grid>

                {/* text notification settings */}
                <Grid item style={{marginBottom: "5%"}}>
                    <Grid container alignItems="center" >
                        <Grid item xs={9}>
                            <Typography className={classes.sectionTitle}>Text Notifications</Typography>
                        </Grid>
                        <Grid item container xs={3}>
                            <Switch
                                checked={textNotificationsSwitchChecked}
                                onChange={handleTextNotificationsSwitchChange}
                                name="notificationSwitch"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color='primary'
                            />
                        </Grid>
                    </Grid>
                    {textNotificationsSwitchChecked &&
                    <Grid container spacing={5} className={classes.editContainers}>
                        <Grid item xs={12} md={6}>
                        <MuiPhoneNumber
                            fullWidth
                            variant="outlined"
                            name="phone"
                            label="Phone Number"
                            defaultCountry={"us"}
                            onlyCountries={['us']}
                            countryCodeEditable={false}
                            disableAreaCodes={true}
                            value={notificationPhoneTextBoxValue}
                            onChange={handleTextNotificationTextBoxValueChange}
                            disabled={!textNotificationTextBoxEnabled}
                            error={!validPhoneNumber}
                        />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    {textNotificationTextBoxEnabled &&
                                        <Button
                                            onClick={handlePhoneNotificationCancelEdit}
                                            startIcon={<Cancel />}
                                            variant="outlined"
                                            style={{color: "red"}}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                    {!textNotificationTextBoxEnabled &&
                                        <Button
                                            onClick={() => setTextNotificationTextBoxEnabled(true)}
                                            startIcon={<Edit />}
                                            variant="outlined"
                                        >
                                            Edit
                                        </Button>
                                    }
                                </Grid>
                                {textNotificationTextBoxEnabled &&
                                    <Grid item xs={6}>
                                        <Button
                                            onClick={handlePhoneNotificationEditSave}
                                            startIcon={<Save />}
                                            variant="outlined"
                                            color="secondary"
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                </Grid>

                {/* email notification settings */}
                <Grid item>
                    <Grid container alignItems="center" >
                        <Grid item xs={9}>
                            <Typography className={classes.sectionTitle}>Email Notifications</Typography>
                        </Grid>
                        <Grid item container xs={3}>
                            <Switch
                                checked={emailNotificationsSwitchChecked}
                                onChange={handleEmailNotificationsSwitchChange}
                                name="notificationSwitch"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                color='primary'
                            />
                        </Grid>
                    </Grid>
                    { emailNotificationsSwitchChecked &&
                    <Grid container spacing={5} className={classes.editContainers}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                disabled={!emailNotificationTextBoxEnabled}
                                value={notificationEmailTextBoxValue}
                                onChange={handleEmailNotificationTextBoxValueChange}
                            />
                        </Grid>
                        {/*<Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={6}>
                                    {emailNotificationTextBoxEnabled &&
                                        <Button
                                            onClick={handleEmailNotificationCancelEdit}
                                            startIcon={<Cancel />}
                                            variant="outlined"
                                            style={{color: "red"}}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                    {!emailNotificationTextBoxEnabled &&
                                        <Button
                                            onClick={() => setEmailNotificationTextBoxEnabled(true)}
                                            startIcon={<Edit />}
                                            variant="outlined"
                                        >
                                            Edit
                                        </Button>
                                    }
                                </Grid>
                                {emailNotificationTextBoxEnabled &&
                                  <Grid item xs={6}>
                                      <Button
                                          onClick={handleEmailNotificationEditSave}
                                          startIcon={<Save />}
                                          variant="outlined"
                                          color="secondary"
                                      >
                                          Save
                                      </Button>
                                  </Grid>
                                }
                            </Grid>
                        </Grid>*/}
                    </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item container direction='column' xs={12} lg={4} align="center" alignItems="center" spacing={5} style={{marginBottom: "5%"}} >
                <Grid item><Typography className={classes.sectionTitle}>Notification Types</Typography></Grid>
                {
                    notificationTypes.map((notification, index) => {
                        return(
                            <Grid key={index} item container direction='column'>
                                <Grid item>
                                    {notification.icon}
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.notificationTypesHeader}>{notification.title}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{notification.description}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    )
                }
            </Grid>
        </Grid>
        }
        </>
    );
}
