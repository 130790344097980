import React, {useState} from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useOktaAuth } from "@okta/okta-react";
import { useRumAction, useRumError } from '@datadog/rum-react-integration';

import {
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    makeStyles,
    Tooltip,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Typography,
    Paper,
    CircularProgress
} from '@material-ui/core';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Cancel from '@material-ui/icons/Cancel';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const ADD_TEAM_MEMBER_ENDPOINT = '/admin/teams/invites';
const REMOVE_TEAM_MEMBER_ENDPOINT = '/admin/teams/remove';
const CHANGE_TEAM_ADMIN = '/admin/teams/teamAdmin';

const useStyles = makeStyles((theme) => ({
    margin: {
      paddingTop:"1vh",
      paddingBottom:"1vh",
      paddingLeft: "1vw"
    },
    container: {
      maxHeight: 500,
    },
    icon: {
      marginTop: "0.3vh"
    },
    sectionHeader: {
      fontSize: 32,
      marginTop: 10,
      marginBottom: 10,
    },
}));


export default function TeamMembers(props){

    var classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [allMembers, setAllMembers] = useState(props.members);
    const [isTeamAdmin, setIsTeamAdmin] = useState(props.user.teamAdmin);
    const [loading, setLoading] = useState(false);
    const { authState, oktaAuth } = useOktaAuth();
    const currUser = authState?.accessToken?.claims;

    const addAction = useRumAction("TeamMembers");
    const addError = useRumError("TeamMembers");

    React.useEffect(() => {
      setAllMembers(props.members);
      setIsTeamAdmin(props.user.teamAdmin);
    }, [props]);

    console.log(allMembers);

    const handleRemoveMember = (index) => {
        if(window.confirm("Are you sure you want to remove the member?")){
            axios.delete('/api' + REMOVE_TEAM_MEMBER_ENDPOINT, {
              data: {
                removeMemberEmailId: allMembers[index].email
              },
              headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken },
            })
            .then(res => {
              var members = allMembers;
              members.splice(index, 1);
              setAllMembers([...members]);
              addAction('RemoveTeamMember');
              enqueueSnackbar("Team member removed successfully!", {variant: 'success'});
            })
            .catch(err => {
                addError('RemoveTeamMember');
                if(err.response){
                    enqueueSnackbar(err.response.data, {variant: 'error'});
                }
            })
        }
    }

    const handleChangeTeamAdmin = (index) => {
        if(window.confirm("Are you sure you want to make this member the team admin?")){
            axios.put('/api' + CHANGE_TEAM_ADMIN, {
                newTeamAdminEmail: allMembers[index].email,
            }, { headers: { 'Authorization': 'Bearer ' + authState.accessToken.accessToken }, })
            .then(res => {
                enqueueSnackbar(res.data, {variant: 'success'});
                setIsTeamAdmin(false);
                addAction('ChangeTeamAdmin');
            })
            .catch(err => {
                addError('ChangeTeamAdmin');
                if(err.response){
                    enqueueSnackbar(err.response.data, {variant: 'error'});
                }
            })
        }
    }

    return(
      <Grid container spacing={1} direction='column'>
        <Typography className={classes.sectionHeader}>Team Members</Typography>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={1} direction="row" alignItems="center" className={classes.margin} wrap="nowrap">
              <Grid item>
                <GroupIcon className={classes.icon}/>
              </Grid>
              <Grid item>
                {props.canviewallorders ? `You have access to view all orders placed by any user at ${props.user.customerName}.` : "You have access to view all orders placed by any of your team members."}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Team Admin</TableCell>
                  <TableCell>Is Active</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allMembers.map((member, index) => {
                  return (
                    <TableRow key={member.email}>
                      <TableCell>{member.name}</TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>
                        {member.teamAdmin && <CheckCircleOutlineIcon style={{color: 'green'}} />}
                      </TableCell>
                      <TableCell>
                        {member.isactive ? 
                          <CheckCircleOutlineIcon style={{color: 'green'}} /> : 
                          <CancelOutlinedIcon style={{color: 'red'}} />
                        }
                      </TableCell>
                      <TableCell>
                        {isTeamAdmin && member.email !== props.user.email &&
                          <>
                            <Tooltip title="Make Team Admin">
                              <Button
                                onClick={() => handleChangeTeamAdmin(index)}
                              >
                                <LabelImportantIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Remove Team Member">
                              <Button
                                onClick={() => handleRemoveMember(index)}
                              >
                                <Cancel style={{color: 'red'}}/>
                              </Button>
                            </Tooltip>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
}
